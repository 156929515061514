@font-face {
    font-family: "MavenPro-Bold";
    src: url("../../fonts/MavenPro-Bold.ttf");
  }
  
  @font-face {
    font-family: "MavenPro-SemiBold";
    src: url("../../fonts/MavenPro-SemiBold.ttf");
  }
  
  @font-face {
    font-family: "MavenPro-Regular";
    src: url("../../fonts/MavenPro-Regular.ttf");
  }

  .select-reporte-utilizacion-relevo {
    width: 100% !important;
    border-color: #48b9fd !important;
    border: 1px solid;
    border-radius: 8px;
  }

  .tarjeta-contenedora-curva-relevos {
    width: 100%;
    max-width: 1750px;
    background-color: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    margin-bottom: 20px;
  }
  
  .detalle-curva-relevos{
    background-color: #48b9fd !important;
    width: 120px !important;
    border-radius: 6px !important;
    font-weight: 900 !important;
    margin-top: 10px !important;
  }

  .modal-detalle-curva-relevos {
    width: 90% !important;
    border-radius: 22px !important;
    padding: 50px !important;
    background-color: #fff !important;
  }

  .modal-detalle-curva-relevos .ant-modal-content{
    box-shadow: none !important;
  }

  .division-detalle-curva-relevos {
    position: relative;
    width: 100%;
    color: #888888 !important;
    margin-bottom: 40px !important;
  }

  .tabla-detalle-curva-relevos table thead tr th {
    background-color: #64b4e5 !important;
    color: #fff;
    font-family: "MavenPro-Bold";
    font-size: 16px;
    border-right: 0px solid #f0f0f0 !important;
  }
  
  .columna-detalle-icono {
    text-align: center !important;
  }

  .descargar-excel-detalle-relevo {
    background-color: #37a537 !important;
    width: 155px !important;
    border-radius: 6px !important;
    font-weight: 900 !important;
    margin-top: 10px !important;
  }

  .tarjetasReporteUtilizacionRelevo {
    text-align: center;
  }

  .tituloTarjetaReporteUtilizacionRelevo {
    font-family: "MavenPro-Bold";
    font-size: 16px;
    letter-spacing: 0px;
    color: #071FCB;
    opacity: 1;
  }

  .valoresTarjetasReporteUtilizacionRelevo {
    font-family: "MavenPro-SemiBold";
    font-size: 46px;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
  }

  .contenedorTitulo {
    height: 50px !important;
  }

  .iconoRelevo {
    width: 50px;
  }

  .iconoBotonDetalleCurvaRelevos {
    display: none !important;
  }

  .iconoExcelBotonDetalleCurvaRelevos {
    display: none !important;
  }

  @media only screen and (max-width: 1650px) {
    .modal-detalle-curva-relevos {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 1450px) {
    .modal-detalle-curva-relevos {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 1260px) {
    .contenedorTitulo {
      height: 65px !important;
    }
  }

  @media only screen and (max-width: 1065px) {

    .tituloTarjetaReporteUtilizacionRelevo{
      font-size: 14px;
    }

    .contenedorTitulo {
      height: 70px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .modal-detalle-curva-relevos {
      width: 100% !important;
      padding: 10px !important;
    }
  }

  @media only screen and (max-width: 500px) {

    .detalle-curva-relevos{
      width: 50px !important;
    }

    .textoBotonDetalleCurvaRelevos{
      display: none !important;
    }

    .iconoBotonDetalleCurvaRelevos {
      display: block !important;
      font-size: 18px
    }

    .descargar-excel-detalle-relevo{
      width: 50px !important;
    }

    .textoBotonDescargarExcelDetalleCurvaRelevos{
      display: none !important;
    }

    .iconoExcelBotonDetalleCurvaRelevos {
      display: block !important;
      width: 25px !important; 
    }

  }

  @media only screen and (max-width: 450px) {

    .iconoRelevo{
      width: 40px;
    }

  }
  
/* 
  @media only screen and (max-width: 380px) {

    .modal-detalle-curva-relevos {

      margin-bottom: 20% !important;

    }

  } */