/* IMAGEN DE CARGA DE DASHBOARD PANTALLA INICIAL */

.img-carga-dashboard {
    width: 830px;
    height: 527px;
    margin-top: 20px !important;
    /* width: 100% !important; */
    border-color: #48b9fd !important;
    border: 1px solid;
    border-radius: 9px;
}

.h1-carga-dashboard{
    font-family: MavenPro-Bold;
    color: #061FCB;
    font-size: 25px;
    letter-spacing: 0px;
}

/* DRAWER */
.drawer-dashboard .ant-drawer-title{
    color: #061FCB !important;
    text-align: left;
    font-family: MavenPro-Bold;
    font-size: 18px;
    letter-spacing: 0px;
    opacity: 1;
}
.drawer-text-dashboard{
    font-family: MavenPro-Regular !important;
}

/* FILTROS DEL DASHBOARD */

.select-style-dashboard {
    width: 100% !important;
    border-color: #48b9fd !important;
    border: 1px solid;
    border-radius: 9px;
}

.btn-filtrar-dashboard{
    width: 100% !important;
    background-color: #64B4E5 !important;
    border-radius: 9px !important;
    text-align: center;
    font-family: "MavenPro-Bold";
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.label-filtros-dashboard {
    margin-top: 8px !important;
    margin-bottom: 5px !important;
    padding-left: 3px !important;
    font-size: 12px !important;
    font-family: "MavenPro-Regular" !important;
}

/* LEYENDA Y INDICADORES */
.card-info-dashboard {
    background:#FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow:0px 3px 6px #00000029 !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    width: 300px !important;
    margin-top: 10px !important;
}


/* VISTA GENERAL DEL DASHBAORD */

.card-style-dashboard{
    border-radius: 16px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    margin-top: 20px !important;
}

.card-style-dashboard .ant-card-head{
    background-color: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
}

.card-title-dashboard .ant-card-head-title{
    text-align: left !important;
    font-size: 16px !important;
    font-family: "MavenPro-Bold" !important;
    color: #061FCB !important;
}

.btn-detalle-dashboard {
    background-color: #64B4E5 !important;
    border-radius: 9px !important;
    text-align: center;
    font-family: "MavenPro-Bold";
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.btn-ineficiente-dashboard {
    background-color: #E64040 !important;
    border: 1px solid #E64040  !important;;
    border-radius: 9px !important;
    text-align: center;
    font-family: "MavenPro-Bold";
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.btn-icon-detalle-dashboard {
    display: none !important;
}

/* Modal Style */
.modal-detalle-dashboard {
    width: 90% !important;
    border-radius: 22px !important;
    padding: 50px !important;
    background-color: #FFFFFF !important;
}

.modal-detalle-dashboard .ant-modal-content{
    box-shadow: none !important;
}

.modal-detalle-ineficiente-dashboard {
    width: 90% !important;
    border-radius: 22px !important;
    padding: 10px !important;
    background-color: #FFFFFF !important;
    opacity: "0.96";
}
.modal-h3-dashboard{
    text-align: left;
    color: #061FCB;
    font-family: "MavenPro-Bold";
    padding-top: 10px;
}

.modal-btn-excel-dashboard {
    background-color: #37a537 !important;
    width: 155px !important;
    border-radius: 6px !important;
    font-weight: 900 !important;
    margin-top: 10px !important;
    font-family: "MavenPro-Bold" !important;
}

.modal-btn-icon-excel-dashboard {
    display: none !important;
}

.modal-division-detalle-dashboard{
    position: relative;
    width: 100%;
    color: #888888 !important;
    margin-bottom: 40px !important;
}

.modal-tabla-detalle-dashboard table thead tr th {
    background-color: #64b4e5 !important;
    color: #ffffff;
    font-family: "MavenPro-Bold";
    font-size: 16px;
    border-right: 0px solid #f0f0f0 !important;
}

.modal-img-tabla-dashboard {
    width: 37px;
    height: 37px;
    border-radius: 20px;
}

.modal-empty-b-dashboard {
    color: #262e2d;
    font-family: "MavenPro-SemiBold";
    font-size: 13px;
    margin: 0px;
}

/* GRAFICO ESTADO TRABAJADORES */
.card-estado-trabajadores-dashboard {
    width: 160px !important;
    height: 270px !important;
    font-family: MavenPro-Bold !important;
    text-align: center !important;
    margin: 1vh !important;
    background-color: #FFFFFF 0% 0% no-repeat padding-box  !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 20px !important;
    margin-top: 1rem;
    display: grid;
    justify-items: center;
}

/* .card-estado-trabajadores-dashboard.ant-card-cover{
    padding-top: 1rem !important;
} */

/* GRAFICO REGIMEN DE TRABAJO */
.card-regimen-trabajadores-dashboard {
    font-family: MavenPro-SemiBold !important;
    background-color: #FFFFFF 0% 0% no-repeat padding-box  !important;
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    border-radius: 10px !important;
    border:1px solid #70707047 !important;
    opacity: 1 !important;
    margin-top: 10px !important;
    margin-right:10px !important;
}

/* GRAFICO CURVA DE RELEVOS */
.card-curva-relevos-dashboard {
    width: 100% !important;
    max-width: 1750px !important;
    background-color: #ffffff !important;
    border: 1px solid #e2e2e2 !important;
    border-radius: 16px !important;
    margin-bottom: 20px !important;
}
/* GRAFICOS DE VENCIMIENTO */

.card-chart-vencimiento-dashboard {
    background-color:#FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    width: 695px !important;
    margin: 7vh !important;
    /* margin-right: 25px !important; */
    /* margin-bottom: 25px !important; */
    opacity: 1 !important;
}

.h1-title-vencimiento-dashboard {
    font-family: MavenPro-Bold !important;
    font-size: 15px !important;
    text-align: left !important;
    color: #262E2D !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
}

.btn-detalle-vencimiento-dashboard {
    font-family: MavenPro-Bold !important;
    font-size: 15px !important;
    text-align: center !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    background: #64B4E5 0% 0% no-repeat padding-box !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
}

/* GRAFICO TRABAJADORES POR ESTADO CARGO */


.col-chart-estado-cargo-dashboard {

    height: 300px!important;
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    box-shadow: 0px 3px 6px #00000029!important;
    border-radius: 20px!important;
    opacity: 1!important;
    margin: 5px!important;
    /* marginTop:"5px",
    marginBottom:"5px",
    marginLeft:"5px",
    marginRight:"5px" */
}

.h2-title-estado-cargo-dashboard {
    text-align: center !important;
    font-family:MavenPro-SemiBold !important;
    font-size:16px !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
    margin-top:5px !important;
    margin-bottom:5px !important;
    padding: 0 !important;
  
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 100% !important; 
    height: 1.2em !important; 
    white-space: nowrap !important;
}

@media only screen and (max-width: 1650px) {
    .img-carga-dashboard {
        width: 800px !important;
        height: 500px !important;
        margin-top: 20px !important;
    }

    /* .card-estado-trabajadores-dashboard {
        margin: 3vh !important;
    } */
    
    .card-chart-vencimiento-dashboard {
        width: 45% !important;
        /* margin-right: 35px !important; */
        margin: 3vh !important;
    }
    .modal-detalle-dashboard {
      width: 100% !important;
    }
}

@media only screen and (max-width: 1450px) {
    .img-carga-dashboard {
        width: 750px !important;
        height: 450px !important;
        margin-top: 20px !important;
    }

    /* .card-estado-trabajadores-dashboard {
        margin: 7vh !important;
    } */
    .card-chart-vencimiento-dashboard {
        width: 45% !important;
        /* margin-right: 35px !important; */
        margin: 2vh !important;
    }
    .modal-detalle-dashboard {
        width: 100% !important;
    }
}
@media only screen and (max-width: 900px) {
    .img-carga-dashboard {
        width: 450px !important;
        height: 350px !important;
        margin-top: 20px !important;
    }
    /* .card-estado-trabajadores-dashboard {
        margin: 3vh !important;
    } */
    .img-carga-dashboard {
        width: 650px !important;
        height: 350px !important;
    }
    .card-chart-vencimiento-dashboard {
        width: 100% !important;
        margin: 1vh !important;
    }
}
@media only screen and (max-width: 700px) {
    .img-carga-dashboard {
        width: 450px !important;
        height: 350px !important;
        margin-top: 20px !important;
    }
    .card-chart-vencimiento-dashboard {
        width: 100% !important;
        margin: 1vh !important;
    }

    .btn-filtrar-dashboard{
        /* width: 50% !important; */
        width: 100% !important; 
    }
    .btn-col-filtrar-dashboard {
        text-align: right !important;
    }
    .modal-detalle-dashboard {
      width: 100% !important;
      padding: 10px !important;
    }

    .modal-btn-excel-dashboard{
        width: 50px !important;
    }

    .modal-btn-text-excel-dashboard{
        display: none !important;
    }

    .modal-btn-icon-excel-dashboard {
        display: block !important;
        width: 25px !important; 
    }
  
}

@media only screen and (max-width: 500px) {
    .img-carga-dashboard {
        width: 350px !important;
        height: 250px !important;
        margin-top: 20px !important;
    }
    /* //AQUI QUEDE 20-05-22 */
    .card-estado-trabajadores-dashboard {
        margin-left: 65px !important;
    }

    .card-chart-vencimiento-dashboard {
        width: 100% !important;
        margin: 1vh !important;
    }

    .btn-filtrar-dashboard{
        width: 50% !important;
    }

    .btn-col-filtrar-dashboard {
        text-align: right !important;
    }

    .text-detalle-dashboard {
        display: none !important;
    }

    .btn-icon-detalle-dashboard{
        display: block !important;
        font-size: 20px
    }

    .modal-btn-excel-dashboard{
        width: 50px !important;
    }

    .modal-btn-text-excel-dashboard{
        display: none !important;
    }

    .modal-btn-icon-excel-dashboard {
        display: block !important;
        width: 25px !important; 
    }
}

