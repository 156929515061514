.modalTransbordo {
  /* width: 75% !important; */
  width: 35% !important;
  top: 15% !important; 
  /* overflow: scroll; */
}

.contenedorTituloModalTransbordo {
  background-color: #f7f7f7;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e2e2e2;
  margin-bottom: 5%;
  opacity: 1;
}

.tituloModalTransbordo {
  color: #061fcb;
  font-family: "MavenPro-Bold";
  font-size: 25px;
  padding-top: 20%;
}

.contenedorModalTransbordo {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}

.button-cerrar-modalTransbordo {
  background-color: #e60aa3;
  border-radius: 13px;
  color: #ffffff;
  font-family: "MavenPro-Bold";
  font-size: 18px;
  opacity: 1;
  padding-top: auto;
  padding-bottom: auto;
  width: 138px;
}

.button-guardar-modalTransbordo {
  background-color: #e60aa3;
  border-radius: 13px;
  color: #ffffff;
  font-family: "MavenPro-Bold";
  font-size: 18px;
  height: 40px;
  opacity: 1;
  /* padding-top: auto;
    padding-bottom: auto; */
  width: 138px;
  position: absolute;
  right: 30px;
  bottom: 10px;
}



@media (max-width: 1650px) {
  .modalTransbordo {
    width: 100% !important;
  }

  /* .contenedortarjetaModalDetalleActividad2 {
      overflow-x: scroll;
    } */
}

@media (max-width: 1450px) {
  .modalTransbordo {
    width: 100% !important;
  }

  /* .botonDetalleGenteDisponible {
      font-size: 14px;
      margin-bottom: 5%;
      width: 100%;
    }
  
    .botonAsignarPlazaGenteDisponible {
      font-size: 14px;
      width: 100%;
    } */
}

@media (max-width: 700px) {
  .modalTransbordo {
    width: 100% !important;
  }

  .contenidoModalTransbordo{
    padding-bottom: 30px !important;
  }
}
