.bordered {
  border: 1px solid #999999 !important;
  border-collapse: collapse !important;
}

.bordered-no-bottom {
  border-top: 1px solid #999999 !important;
  border-left: 1px solid #999999 !important;
  border-right: 1px solid #999999 !important;
}

.bordered-no-top {
  border-left: 1px solid #999999 !important;
  border-right: 1px solid #999999 !important;
  border-bottom: 1px solid #999999 !important;
}

.comprobante-content-wrapper {
  margin-top: 25px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;

  font-family: "MavenPro-Regular";
}

.t-border {
  text-align: center !important;
  border: 1px solid #999999 !important;
  border-collapse: collapse !important;
}

.vacas-table {
  text-align: left !important;
  border: 1px solid #999999 !important;
  border-collapse: collapse !important;
}

.vacas-table-dias {
  text-align: center !important;
  border: 1px solid #999999 !important;
  border-collapse: collapse !important;
  font-weight: 900 !important;
}

.bold-text {
  font-weight: 900 !important;
}

.signos-table {
  position: absolute !important;
  right: 70px !important;
}

.layoutImpresion {
  z-index: 0 !important;
}
