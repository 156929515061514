/*CSS LICENCIAS*/

.CardMaestra{
    width: 700px;
    border-radius: 16px;
    box-shadow: rgba(116, 116, 116, 0.396) 0px 3px 6px;
}

.CardMaestra div{
    padding: 0px;
}
.CardHeader{
    border-radius: 16px;
    box-shadow: rgba(116, 116, 116, 0.396) 0px 3px 6px;
    
}
.CardHeader div {
    padding: 20px!important;
}

.CardFirma{
    background-color: #F4F4F4;
    padding-top: 50px!important;
    padding-bottom: 30px!important;
}

.contFirmas{
    text-align: center;
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.NombresFirma{
    color: #262E2D;
    font-family: "MavenPro-Regular";
    font-size: 12px;
}

.ImpresionTitulo{
    color: #262E2D;
    font-family: "MavenPro-Bold";
    font-size: 20px;
}

.ImpresionLogoEmpresa{
    width: 50px;
    height: 50px;
    border-radius: 16px;
}

.ImprimirNombreCampo{
    padding: 10px;
    color:#262E2D;
    font-family: "MavenPro-Regular";
}

.ImprimirValorCampo{
    padding: 10px;
    background-color: #F4F4F4;
    border-radius: 16px;
    font-family: "MavenPro-Regular";
    color:#262E2D;
}

@media print {
    .ant-layout-sider{
        display:none;
    }
    .quitarImpresion{
        display: none;
    }
    .layoutImpresion {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}