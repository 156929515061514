.titulo-dashboard-dinamico-trabajador{
    color: #061FCB;
    font-family: "MavenPro-Bold";
    font-size: 26px;
    margin-bottom: 0px !important;
}

.sub-titulo-dashboard-dinamico-trabajador{
    color: #061FCB;
    font-family: "MavenPro-Bold";
    font-size: 16px;
}