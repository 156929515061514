.persona {
  border-radius: 20px;
}

.header-plaza {
  text-transform: capitalize;
  color: #262e2d;
  font-family: "MavenPro-Bold";
}

.img-icon-trabajador {
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 31px;
  height: 31px;
}

.titulos-buscar-trabajador {
  color: #061fcb;
  font-size: 14px;
  font-family: "MavenPro-Bold";
}

.cont-trabajador {
  margin-top: 30px!important;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  -ms-overflow-style: none; /* IE and Edge /
  scrollbar-width: none; / Firefox */
}

.cont-trabajador::-webkit-scrollbar {
  display: none;
}

/*css trabajadores*/

.seccion-trabajador {
  /* margin-top: 20px;
  margin-bottom: 20px; */
  /* padding-bottom: 20px;
  padding-top: 20px; */
  padding: 10px 10px 20px 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
  border-radius: 5px 5px 0 0;
  max-width: 96%;
  margin-left: 2%;
  margin-bottom: 40px;
  border-radius: 20px;

}

.seccion-trabajador:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
}


.seccion-datepicker {
  margin-top: 20px;
}

.seleccion-trabajador-img {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}

.seleccion-trabajador-icon {
  margin-top: 4px;
  width: 100%;
  height: 40px;
  border-radius: 30px;
}

.seleccion-trabajador-nombre {
  color: #061fcb;
  font-size: 15px;
  font-family: "MavenPro-Bold";
  margin: 0px;
}

.seleccion-trabajador-disponible {
  color: #262e2d;
  font-family: "MavenPro-SemiBold";
  font-size: 13px;
  margin: 0px;
}

.seleccion-trabajador-relevo {
  color: #262e2d;
  font-family: "MavenPro-SemiBold";
  font-size: 13px;
  margin: 0px;
}

.seleccion-trabajador-img-trabaja-desde {
  color: #262e2d;
  font-family: "MavenPro-Regular";
  font-size: 13px;
  margin: 0px;
}

.color-item-gris {
  color: grey !important;
}

.cont-plaza {
  padding-left: 50px;
}

.cont-sub-titulo {
  padding-left: 10px;
}

.ant-popover-inner-content {
  text-align: center !important;
}

.campo-plaza-light {
  font-family: "MavenPro-Regular";
  font-size: 12px;
}

.iconTransbordo {
  height: 27px;
  fill: #262626;
}

/* .iconTransbordo:hover {
  fill: #ffffff;
} */
