.padding-0 {
  padding: 0px !important;
}

.header-epp {
  border-radius: 16px;
  box-shadow: 0px 3px 3px rgba(194, 194, 194, 0.396);
  padding: 20px !important;
}

.impresion-epp-content {
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 20px !important;
}

.titulo-impresion-epp {
  font-family: "MavenPro-Bold" !important;
}

.parr-impresion-epp {
  font-family: "MavenPro-Regular" !important;
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0px !important;
}

.texto-plano-epp {
  font-family: "MavenPro-Regular" !important;
  margin: 0px !important;
}

.texto-datos-epp {
  font-family: "MavenPro-Regular" !important;
  background-color: #efefef !important;

  width: 100% !important;

  padding: 3px !important;
  padding-left: 10px !important;
  margin: 0px !important;

  border-radius: 7px !important;
}

.tabla-epps {
  width: 100% !important;

  border: 1px solid #eeeeee !important;
  border-collapse: collapse !important;
}

.tabla-epps td,
.tabla-epps th,
.tabla-epps tr {
  border: 1px solid #dddddd !important;
  border-collapse: collapse !important;
  font-family: "MavenPro-Regular" !important;
}

.tabla-epps th {
  text-align: center !important;
  background-color: rgb(238, 238, 238) !important;
  font-weight: 900 !important;
}

.tabla-epps td {
  height: 50px !important;
}

.td-variable-alto td {
  height: 40px !important;
}

.td-variable-bajo td {
  height: 30px !important;
}

.letra-chica-epp {
  font-family: "MavenPro-Regular" !important;
  font-size: 9px !important;
  line-height: 10px !important;
  text-align: justify !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.epp-firma-card {
  background-color: #f4f4f4;
  padding-top: 60px !important;
  padding-bottom: 15px !important;
}

.observaciones-div {
  font-size: 14px !important;
  padding-left: 20px !important;
  font-family: "MavenPro-Regular" !important;
  border-top: 1px solid #dddddd !important;
  height: 50px !important;
}
