.logo {
  margin: 18px;
  margin-bottom: 0px;
  z-index: 1000;
}

.imgSidebar {
  width: 1.5em !important;
  height: auto !important;

  position: relative;
  top: 4px;
}

.imgSidebar-admin {
  width: 1.2em !important;
  height: auto !important;

  position: relative;
  top: 4px;
}

.m-fix {
  margin: 20px;
}

.titulos-sidebar {
  font-size: 12px;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #061fcb !important;
}

.ant-menu-dark {
  background: #061fcb !important;
}

.ant-menu-sub {
  background: #061fcb !important;
  font-size: 30px;
}

.ant-menu-submenu {
  font-family: MavenPro-Medium;
  font-size: 16px;
  color: white !important;
  vertical-align: middle;
}

.ant-layout-sider-children {
  background-color: #061fcb;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  /* overflow-y: scroll;
    overflow-x: hidden; */
  z-index: 900;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}

/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(255, 255, 255, 1);
  border-top-left-radius: 90px;
  border-bottom-left-radius: 90px;
  color: #061fcb !important;
  width: 105%;
} */

.ant-menu-item-selected span a {
  color: #000000 !important;
}
.ant-layout-sider-trigger {
  display: none;
}

.ant-menu-item {
  font-family: MavenPro-Medium;
  font-size: 12px;
  color: white !important;
  /* padding-left: 0 !important; */
}

.ant-layout-sider {
  background-color: #061fcb;
}

.ant-menu-item-selected {
  width: 100% !important;
}

.side-title {
  color: white;
  font-size: 10px;
  font-family: "MavenPro-Bold";
  /* position: absolute;
  margin-left: 3px;
  top: 91vh;
  line-height: 13px; */

  display: block;
  width: 63px !important;
  margin: 12px auto;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
  /* border-top-left-radius: 90px;
    border-bottom-left-radius: 90px; */
  color: #061fcb !important;
  width: 100%;
  /* padding-left: 25px; */
  align-content: center;
}

.ant-menu-dark .ant-menu-item-selected > a {
  color: #061fcb !important;
}

.ant-menu-dark .ant-menu-item-selected > span > a {
  color: #061fcb !important;
}

.invisible {
  display: none;
}

.menu-icons-absolute {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.menu-icons-relative {
  position: relative;
  top: 20%;
}

.fs-icon-side {
  margin-left: 30px;
  text-decoration: none;
  color: #ffff;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 30px;
  text-overflow: clip;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 25px;
  line-height: 24px;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 1);
}
/* 
.invisible {
  display: flex;
} */

.boton-logout {
  width: 35px !important;
  display: block;
  margin: 0 auto;
  margin-bottom: 23px !important;
}

.boton-logout-mobile {
  width: 35px !important;
  display: block;
  margin: 0 auto;
  /* margin-bottom: 23px !important; */
}

.boton-logout-mobile-wrapper {
  position: absolute;
  bottom: 3%;
  left: 40%;
}

.full-invisible {
  display: none !important;
}

.menu-mobile {
  z-index: 1000;

  height: 100vh;
  position: fixed;
  top: 0;

  min-height: 100vh !important;
  box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.3);
}

.mobile-header {
  background-color: #061fcb;
}

/* .menu-sidebar{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70%;
} */

.menu-sidebar {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-sidebar::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 799px) {
  .invisible-desktop {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .invisible-mobile {
    display: none;
  }

  .mobile-header {
    display: none;
  }
}
