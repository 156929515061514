.contenedor {
  margin-top: 40px !important;
}

.formulario-trabajador {
  width: 92%;
}
.form {
  margin-top: 20px !important;
  font-family: MavenPro-Regular;
}

.ant-collapse-header {
  border-radius: 16px !important;
  border: 1px solid #e2e2e2;
  height: 37px !important;
  background-color: #f7f7f7;
  font-family: MavenPro-bold;
  font-size: 17px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.site-collapse-custom-panel {
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  background-color: #ffffff !important;
}
.ant-collapse-content-active {
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border: 1px solid #e2e2e2;
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
}

.ant-select-selector {
  border-radius: 8px !important;
}

.guardar-form {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: white !important;
  background-color: #e60aa3 !important;
  border-radius: 16px !important;
}

.ant-collapse-arrow svg {
  display: none !important;
}
