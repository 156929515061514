.oculto {
  display: none;
}
.cCargando {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  background-color: #ffffff;
  opacity: 0.97;
  z-index: 9999;
  -webkit-transition: width 2s;
  cursor: progress;
}
.cCargandoImg {
  cursor: progress;
  display: flex;
  min-height: 100vh;
  min-width: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(8, 16, 59);
  opacity: 0.96;
  color: #808080;
  min-width: 50%;
  min-height: 100%;
}

.GifPantallaCarga {
  max-width: 100px;
  height: auto;
  opacity: 0.8;
}

@media screen and (max-width: 575px) {
  .GifPantallaCarga {
    max-width: 20%;
  }
}
