@font-face {
  font-family: "MavenPro-Medium";
  src: url("../../fonts/MavenPro-Medium.ttf");
}

@font-face {
  font-family: "MavenPro-SemiBold";
  src: url("../../fonts/MavenPro-SemiBold.ttf");
}

.tabla-detalle-actividad
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  /* padding-left: 52px !important; */
  margin: 0px 0px 0px 52px !important;
}

.select-plazas-detalle-actividad {
  border-radius: 8px !important;
  width: 100% !important;
}

.boton-filtrar-detalle-actividad {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.tarjeta-contenedora-detalle-actividad {
  width: 100%;
  max-width: 1500px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
}

.tabla-detalle-actividad .ant-table-thead > tr > th {
  background: #fff !important;
  color: #262e2d !important;
}

.columna-marca-no-cubierta-tabla-actividades {
  width: 5px !important;
  min-width: 5px !important;
  /* display:  !important; */
  /* align-items: center !important; */
}

.columna-marca-no-cubierta-tabla-actividades.ant-table-cell {
  padding: 0px !important;
}

.columna2-marca-no-cubierta-tabla-actividades {
  width: 5px !important;
  min-width: 5px !important;
}

.columna2-marca-no-cubierta-tabla-actividades.ant-table-cell {
  padding: 0px !important;
}

.columna-plazas-tabla-actividades {
  font-family: "MavenPro-Medium" !important;
  color: #262e2d !important;
  width: 280px !important;
  min-width: 280px !important;
}

.columna2-nombre-trabajador-tabla-actividades {
  width: 290px !important;
  min-width: 290px !important;
}

.columna-dias-asignados-tabla-actividades {
  font-family: "MavenPro-Medium" !important;
  color: #262e2d !important;
  width: 70px !important;
  min-width: 70px !important;
  text-align: center !important;
}

.columna2-dias-asignados-actividades {
  width: 85px !important;
  min-width: 85px !important;
  text-align: center !important;
}

.columna-dias-no-asignados-tabla-actividades {
  font-family: "MavenPro-Medium" !important;
  color: #262e2d !important;
  width: 70px !important;
  min-width: 70px !important;
  text-align: center !important;
}

.columna2-dias-no-asignados-actividades {
  width: 85px !important;
  min-width: 85px !important;
  text-align: center !important;
}

.columna-cronograma-tabla-actividades {
  width: 350px !important;
  min-width: 350px !important;
  text-align: center !important;
}

.columna2-cronograma-actividades {
  width: 350px !important;
  min-width: 350px !important;
  text-align: center !important;
}

.columna-acciones-tabla-actividades {
  width: 270px !important;
  min-width: 270px !important;
  text-align: center !important;
}

.columna2-acciones-actividades {
  width: 270px !important;
  min-width: 270px !important;
  text-align: center !important;
}

.tabla-contenido-detalle-actividad .ant-table-thead {
  display: none;
}

.boton-asignar-trabajador-detalle-actividad {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.editar-button-detalle-actividad {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 100%;
  height: 34px;
}

.eliminar-button-detalle-actividad {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 100%;
  height: 34px;
}

.transbordo-button-detalle-actividad {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 100%;
  height: 34px;
}

.badge-no-asignado-detalle-actividad .ant-badge-status-dot {
  width: 15px !important;
  height: 15px !important;
}

.boton-agregar-plaza-detalle-actividad {
  margin-top: 15px;
  margin-left: 20px;
  font-family: "MavenPro-SemiBold";
  font-size: 14px;
  width: 190px;
  border-color: #fff;
  display: flex;
  height: 30px;
}

.modalAsignarTrabajadorActividad {
  width: 35% !important;
  top: 15% !important;
}

.modalAsignarTrabajadorActividad .ant-modal-content{
  border-radius: 15px !important;
}

.modalModificarActividad {
  width: 55% !important;
  top: 15% !important;
}

.modalModificarActividad .ant-modal-content{
  border-radius: 15px !important;
}

.camposAgregarPlaza {
  border-radius: 8px !important;
  width: 100% !important;
}

.iconoRelevoTable{
  width: 24px;
  height: 24px;
  opacity: 1;
}

.iconoRelevoCard{
  width: 37px;
  height: 37px;
  opacity: 1;
}

.columna-relevo-tabla-actividades {
  font-family: "MavenPro-Medium" !important;
  color: #262e2d !important;
  width: 70px !important;
  min-width: 70px !important;
  text-align: center !important;
}

.columna2-relevo-actividades {
  width: 85px !important;
  min-width: 85px !important;
  text-align: center !important;
  /* background: #64b4e5 !important; */
}

.drawerAsignarTrabajador .ant-drawer-content-wrapper {
  width: 25% !important;
}
.iconoRelevoDrawerVer{
  width: 65px;
  height: 65px;
  opacity: 1;
}

@media (max-width: 768px) {
  .iconoRelevoDrawerVer{
    width: 65px;
    height: 65px;
    /* margin-right: 20px; */
    opacity: 1;
  }

  .modalModificarActividad {
    width: 100% !important;
    top: 15% !important;
  }
  
  .modalModificarActividad .ant-modal-content{
    border-radius: 15px !important;
  }

  .modalAsignarTrabajadorActividad {
    width: 100% !important;
    top: 15% !important;
  }
  
  .modalAsignarTrabajadorActividad .ant-modal-content{
    border-radius: 15px !important;
  }
  
  .drawerAsignarTrabajador .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  
  .select-plazas-detalle-actividad {
    margin-bottom: 10px;
  }

  .range-picker-detalle-actividad {
    margin-bottom: 10px;
  }

  .tarjeta-contenedora-detalle-actividad {
    width: 100% !important;
  }

  .boton-filtrar-detalle-actividad {
    float: right;
  }
}

@media (max-width: 576px) {
  .iconoRelevoDrawerVer{
    width: 65px;
    height: 65px;
    /* margin-right: 20px; */
    opacity: 1;
  }

  .modalModificarActividad {
    width: 100% !important;
    top: 15% !important;
  }
  
  .modalModificarActividad .ant-modal-content{
    border-radius: 15px !important;
  }
  
  .modalAsignarTrabajadorActividad {
    width: 100% !important;
    top: 15% !important;
  }
  
  .modalAsignarTrabajadorActividad .ant-modal-content{
    border-radius: 15px !important;
  }
  .drawerAsignarTrabajador .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .select-plazas-detalle-actividad {
    margin-bottom: 10px;
  }

  .range-picker-detalle-actividad {
    margin-bottom: 10px;
  }

  .tarjeta-contenedora-detalle-actividad {
    width: 100% !important;
  }

  .boton-filtrar-detalle-actividad {
    float: right;
  }
}
