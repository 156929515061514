.cantidad-alertas {
  display: contents;
  background-color: red;
}

.alerta-detallada {
  position: absolute;
  z-index: 1;
  top: -0.5em;
  left: 100%;
  margin: 0 0 0 -1em !important;
  background-color: #e00070;
  color: white;
  font-family: "AvenirNext-Regular";
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em;
  background-image: none;
  padding: 0.3833em 0.533em;
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  transition: background 0.1s ease;
}
.alerta-detallada p {
  padding: 0px;
}

.tarjetas-trabajador-text {
  font-family: "MavenPro-SemiBold";
  font-size: 15px;
  line-height: 18px !important;

  color: #262e2d;

  text-align: left !important;
  vertical-align: middle !important;
  margin-left: 2%;
  margin-bottom: 0px !important;
}

.tarjetas-trabajador-col {
  height: fit-content !important;
}

.item-tooltip-calendario p {
  margin-bottom: 5px !important;
}

/* Clases para hacer desaparecer líneas verticales y horizontales de timelines del proyecto */

/* .rct-vl {
  display: none !important;
}

.rct-hl-odd,
.rct-hl-even {
  display: none !important;
} */
