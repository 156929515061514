/* .perfil img {
  width: 35% !important;
  height: auto !important;
  margin-left: 30px;
} */

.perfil {
  box-shadow: 0px 6px 12px #0000004b !important;
  text-align: center !important;
  /* width: 333px !important;
  height: 289px !important; */
  /* margin-left: 25px !important;
  margin-top: 40px !important; */
  line-height: 15px !important;
}

.info-trabajador {
  color: #262e2d !important;
  font-size: 15px;
  font-family: MavenPro-Regular !important;
}

.fecha-embarque {
  color: #061fcb !important;
  font-family: MavenPro-Bold !important;
  font-size: 20px;
  line-height: 20px !important;
}

/* .subir-foto {
  position: absolute;
  top: 120px;
  width: 10vw;
}

.avatar {
  border-radius: 50% !important;
  width: 40% !important;
  min-height: 102px !important;
  max-height: 102px !important;
} */
