.maven-r {
  font-family: "MavenPro-Regular" !important;
}

.maven-b {
  font-family: "MavenPro-SemiBold" !important;
}

.titulo-semaforo {
  font-size: 16px !important;
  margin-bottom: 12px !important;
  margin-left: 5.7% !important;
}

.filtro-desviaciones {
  width: 100% !important;
}

.filtrar-reporte-btn {
  background-color: #48b9fd !important;
  width: 100px !important;
  border-radius: 6px !important;
  font-weight: 900 !important;
  margin-top: 35px !important;
}

.percent-fixed-char {
  z-index: 2 !important;
  position: absolute !important;
  right: 20px !important;
  bottom: 5px !important;
  color: #bbbbbb !important;
}

.label-filtros {
  margin-top: 8px !important;
  margin-bottom: 5px !important;
  padding-left: 3px !important;
  font-size: 12px !important;
}

.semaforo-container {
  margin-top: 30px !important;
  text-align: center !important;
}

.semaforo-div {
  width: 95% !important;
  /* padding-right: 25% !important;
  margin-left: auto !important;
  margin-right: auto !important; */
}

.semadoro-card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3) !important;

  margin-top: 35px !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.axis-cantidad-trab {
  color: #888888;
  font-size: 12px !important;

  display: block !important;
  transform: rotate(-90deg);
  white-space: nowrap !important;
  position: relative !important;
  top: 35% !important;
}

#semaforo-div {
  display: inline-block !important;
}

.percentages-wrapper {
  pointer-events: none !important;
  z-index: 80 !important;
  position: absolute !important;

  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  padding-right: 4% !important;
  padding-left: 2% !important;

  font-weight: 900 !important;
  font-size: 30px !important;
  color: rgba(100, 100, 100, 0.5);
}

.card-desv-trabajador {
  /* box-shadow: 0px 2px 2px rgba(100, 100, 100, 0.2) !important; */
  border: 1px solid rgb(235, 237, 240) !important;
  border-radius: 7px !important;

  margin-bottom: 15px !important;
  padding-top: 20px !important;
}

.img-desv-container {
  width: 35% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 14px !important;
}

.trab-img-desv-wrapp {
  position: relative !important;
  width: 100% !important;
  padding-bottom: 100% !important;
}

.trab-img-desv {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  border-radius: 50% !important;
}

.desv-trab-info-col {
  /* border-right: 1px solid #bbbbbb !important; */
  padding-right: 3% !important;
  padding-left: 3% !important;
  padding-top: 6px !important;
  padding-bottom: 10px !important;

  background-color: #eeeeee !important;
  text-align: center !important;
}

.trab-info-title {
  color: #061fcb !important;
  font-family: "MavenPro-SemiBold" !important;
  margin-bottom: 2px !important;
}

.trab-info-parr {
  font-family: "MavenPro-Regular" !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.desv-trab-info-col p {
  margin-bottom: 2px !important;
  /* border-bottom: 1px solid rgba(100, 100, 100, 0.1) !important; */
}

.icon-status-desv {
  width: 40px !important;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.desviacion-perc {
  font-family: "MavenPro-Bold" !important;
  font-weight: 900 !important;
  color: #061fcb !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.desviacion-perc-grande {
  font-family: "MavenPro-Bold" !important;
  font-size: 25px !important;
  color: #061fcb !important;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

.desviacion-perc-subtext {
  font-family: "MavenPro-Bold" !important;
  font-weight: 900 !important;
  color: #061fcb !important;
  margin-bottom: 0px !important;
}

.periodo-desv-parr {
  margin-bottom: 3px !important;
}

.status-desv-col {
  text-align: center !important;
  height: fit-content !important;
  /* margin-top: auto !important;
  margin-bottom: auto !important; */
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.trab-desv-row {
  border-top: 1px solid rgb(235, 237, 240) !important;
  margin-top: 0px !important;
  /* height: 150px !important; */
}

.detalle-trab-btn {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 42px;
  height: 34px;

  color: #333333 !important;
}

.detalle-trab-btn:hover {
  background-color: #4871c9 !important;
  color: white !important;
}

.modal-header-wrapper {
  border: 1px solid rgb(235, 237, 240) !important;
  padding-bottom: 18px !important;

  margin-top: 12px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
  margin-bottom: 15px !important;

  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.desv-info-card {
  padding-right: 8% !important;
  padding-left: 8% !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;

  margin-top: 10px !important;

  background-color: #eeeeee !important;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.salto-linea-responsive {
  display: none !important;
}

.pl-0-mobile {
  padding-left: 7px !important;
}

.pr-0-mobile {
  padding-right: 7px !important;
}

.ver-detalle-div {
  margin-left: 5.7% !important;

  border: 1px solid #40a9ff !important;
  border-radius: 5px;
  padding: 0px !important;

  width: fit-content !important;
  height: fit-content !important;
}

.ver-detalle-button-span {
  background-color: #40a9ff !important;
  height: 100% !important;
  border-radius: 5px !important;
}

.popover-table-div {
  width: 230px !important;
}

.popover-table-div table,
.popover-table-div td {
  border: 1px solid #cccccc !important;
  border-collapse: collapse !important;
}

@media screen and (max-width: 767px) {
  .filtrar-reporte-btn {
    margin-top: 20px !important;
  }

  .salto-linea-responsive {
    display: inline !important;
  }

  .status-desv-col {
    padding-top: 30px !important;
    padding-bottom: 10px !important;
  }

  .pl-0-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pr-0-mobile {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
