.fondo-login {
  background-image: url("../../img/login/fondoLogin.png") !important;
  background-repeat: no-repeat !important;
  background-size: 91% !important;
  position: relative;
  left: 0;
  width: 100%;
  height: 100vh;
}

.fondo-mobile-1 {
  width: 100%;
  display: none;
}

.fondo-mobile-2 {
  width: 100%;
  display: none;
}

/* .fondo-mobile-1 {
  background-image: url("../../img/login/back-top.png") !important;
  background-repeat: no-repeat;
  background-size: 100% !important;

  height: 300px !important;

  display: none;
}

.fondo-mobile-2 {
  background-image: url("../../img/login/back-bottom.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  height: 200px !important;
  display: none;
} */

@media screen and (max-width: 767px) {
  .fondo-login {
    background-image: none !important;
  }

  .fondo-mobile-1 {
    display: block;
  }

  .fondo-mobile-2 {
    display: block;
  }

  .fondo-login {
    height: fit-content !important;
    margin-top: 10%;
    margin-bottom: 15%;
  }
}
