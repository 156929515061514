.contenedor-header-tabla-derecha {
  align-self: flex-end;
}

.contenedor-derecho-data {
  display: grid;
}

.cont-numeros-items-asistencia-personas {
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  font-size: 15px;
  color: black;
  font-family: "MavenPro-Regular";
}

.regular-maven {
  font-family: "MavenPro-Regular" !important;
}

.color-fondo-verde {
  background-color: #52d68f;
}

.color-fondo-amarillo {
  background-color: #efc12b;
}

.color-fondo-rojo {
  background-color: #e64040;
}

.ant-input-search-button {
  background-color: #64b4e5;
  border-color: 1px solid #64b4e5 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.group-row {
  height: 300px !important;
}

.rep-asistencia-filtrar-btn {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: fit-content !important;
}

.rep-asistencia-excel-btn {
  background-color: #44bb26;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: fit-content !important;
}

.leyenda-table,
.leyenda-table td {
  border: 1px solid #dddddd !important;
  border-collapse: collapse !important;
  text-align: center !important;
}

.leyenda-table td {
  width: fit-content !important;
  padding: 1px 3px 3px 3px !important;
}

.leyenda-table p {
  margin-bottom: 0px !important;
}

.leyenda-item {
  height: 20px !important;
  width: 20px !important;

  border-radius: 50% !important;

  color: white !important;
  font-size: 12px !important;
  text-align: center !important;
  line-height: 20px !important;

  margin-left: auto !important;
  margin-right: 5px !important;

  display: inline-block !important;
}

.ley-verde {
  background-color: #52d68f !important;
}

.ley-celeste {
  background-color: #3cadf0 !important;
}

.ley-morado {
  background-color: #7c8dfc !important;
}

.ley-naranjo {
  background-color: #ef7720 !important;
}

.ley-parr {
  display: inline-block !important;
  margin-right: 10px !important;
  margin-bottom: 10px;
}
