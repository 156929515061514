.ant-page-header-heading-title {
  color: #001fcb !important;
  font-family: "MavenPro-Bold";
  line-height: 40px !important;
}

.ant-page-header-heading-sub-title {
  color: black !important;
  font-family: "MavenPro-Regular";
  position: absolute;
  bottom: 0;
}

.ant-layout {
  background-color: white !important;
}

.iconos {
  width: 35% !important;
  height: auto;
  position: relative;
  top: 18%;
  right: 50px;
}

.ant-layout-header {
  padding: 0 !important;
}

.cerrar-sesion-btn {
  width: fit-content !important;
  background-color: #b2b2b2 !important;
  color: white !important;

  border-radius: 14px !important;
}

@media screen and (max-width: 799px) {
  .display-none-mobile {
    display: none !important;
  }

  .empresas-selector {
    position: absolute !important;
    top: -70% !important;
    right: 0% !important;
    width: 200px !important;
  }

  .cerrar-sesion-btn{
    display: none !important;
  }
}

@media screen and (max-width: 1100px) {
  .empresas-selector {
    width: 200px !important;
  }
}
