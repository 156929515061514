.qr-block {
    width: 90%;
    margin: 0% auto;
}

.qr-wrapper {
    background-color: #efefef;

    min-width: 100%;
    height: fit-content;

    padding-top: 10%;
    padding-bottom: 20%;
    margin-top: 5%;
}

.select-asistencia {
    width: 80% !important;
}

.reporte-filtrar-btn {
    width: 50% !important;
}

.qr-col-select {
    text-align: center !important;
}

.qr-col-button {
    text-align: center !important;
}

@media screen and (min-width: 992px) {
    .qr-block {
        width: 30%;
    }
}

@media screen and (min-width: 600px) {
    .qr-block {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .display-none-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .reporte-filtrar-btn {
        width: 50% !important;
    }

    .select-asistencia {
        width: 90% !important;
    }

    .qr-col-select {
        text-align: center !important;
    }

    .qr-col-button {
        text-align: right !important;
        padding-right: 7% !important;
    }
}
