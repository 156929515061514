/*CSS HEADER CALENDARIO TIME LINE*/
.react-calendar-timeline .rct-dateHeader-primary {
  background-color: white;
  color: #a9a6a6;
}

.react-calendar-timeline .rct-dateHeader {
  border-left: 0px;
}

.custom-group {
  height: 100px;
}

.custom-group-narrow {
  height: 70px !important;
}

.header-plaza {
  font-family: "MavenPro-Bold";
  font-size: 16px;
}

.react-calendar-timeline .rct-header-root {
  background: white;
  /* bottom: 0; */
}

/* .nombre-faena-izq{

} */
/*CSS HEADER CALENDARIO TIME LINE*/

/*CSS TABLA DE LINEAS*/
/* .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 0px;
} */

.img-icon {
  width: 31px;
  height: 31px;
}

.campo-plaza {
  font-family: "MavenPro-Bold";
  font-size: 12px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 0px;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: white;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: white;
}
/*CSS TABLA DE LINEAS*/
.rct-sidebar-row-even,
.rct-sidebar-row-odd {
  font-family: MavenPro-bold;
}

/*status de asignacion de faena*/
.ant-badge-status-warning {
  background-color: #efc12b;
}

.ant-badge-status-processing {
  background-color: #52d68f;
}

.ant-badge-status-success {
  background-color: #7c8dfc;
}

.ant-badge-status-error {
  background-color: #e64040;
}

.rct-dateHeader-primary {
  font-family: MavenPro-Bold;
  color: #061fcb !important;
  font-size: 25px !important;
  background-color: #f4f4f4 !important;
}

.mesHeader {
  height: 65px !important;
  border: none !important;
  text-transform: capitalize;
}
.rct-dateHeader {
  border: none;
}
.calendarioHeader {
  border: none !important;
}
