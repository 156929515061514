@media screen and (min-width: 900px) {
    .imgTop {
        width: 28% !important;
        height: 130% !important;
    }

    .imgBottom {
        width: 25% !important;
        height: 160% !important;
        margin-top: -8.9% !important;
    }
}
