.trabajador-img-col {
  position: static;
  min-height: 100% !important;
}

.card-content-row {
  align-items: flex-start !important;
}

.ver-detalle-btn {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 140px;
}

.filtrar-check-btn {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 140px;
}

.checkin-masivo-btn {
  background-color: #E60AA3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 140px;
}

.modal-trabajador-img-container {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 14px !important;
}

.modal-trabajador-img-wrapper {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.modal-trabajador-img {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  border-radius: 50% !important;
}

.modal-container {
  width: 55% !important;
}

.modal-container-checkout {
  width: 70% !important;
}

.check-in-table {
  width: 85% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.check-in-table tr {
  height: 34px !important;
}

.check-in-revision-table {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.check-in-revision-table th,
.check-in-revision-table tr {
  padding: 2px 5px 2px 5px !important;
}

.check-in-revision-table tr {
  height: 34px !important;
}

.check-in-revision-table,
.check-in-revision-table td,
.check-in-revision-table th {
  border: 1px solid #dddddd !important;
  border-collapse: collapse !important;
}

.checkBoxCheckinMasivo.ant-checkbox-wrapper > span:nth-child(2){
  width: calc(100% - 16px); 
  /* padding-left: 15px; */
}

@media screen and (max-width: 575px) {
  .trabajador-img {
    width: 42px !important;
    height: 42px !important;
  }

  .trabajador-img-col {
    height: fit-content !important;
  }

  .card-content-row {
    align-items: center !important;
  }
  .text-no-margin-mobile {
    font-size: 14px !important;
    margin: 0 !important;
  }

  .ver-detalle-btn {
    width: 100%;
    overflow: hidden;
    padding-left: 5px;
  }

  .filtrar-check-btn {
    width: 50%;
  }

  .responsive-filters {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .filter-btn-col {
    text-align: -webkit-center !important;
  }

  .header-subtitle-responsive {
    width: 90vw !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text-nombre-trabajador {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 850px) {
  .modal-container {
    width: 90% !important;
  }
  .modal-container-checkout {
    width: 90% !important;
  }
}

@media screen and (max-width: 992px) {
  .modal-trabajador-img-container {
    width: 35% !important;
  }
}
