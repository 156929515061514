.card_content--text {
  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 122px;
  padding-right: 122px;
  color: #061fcb;
  font-family: "MavenPro-Bold";
}

.formVacaciones_card_title {
  font-size: 21px;
  font-family: "MavenPro-Bold";
  color: #061fcb;
  padding-top: 10px;
  margin-left: 18px;
}

.inputsFormVacaciones_Text {
  font-family: "MavenPro-Regular";
}

.vacacionesBtn_guardar {
  margin-left: 50%;
}

.popover-table,
.popover-th,
.popover-td {
  border: 1px solid #cccccc;
  padding: 10px !important;
}

.periodo-adelantado-text {
  color: salmon !important;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .card_content--text {
    padding-left: 53px;
    padding-right: 53px;
  }
  .vacacionesBtn_guardar {
    margin-left: 61%;
  }
}
