.ant-card-head-title {
  text-align: center;
  color: white;
  font-family: "MavenPro-Bold";
  font-weight: bold;
}

.login {
  border-bottom-right-radius: 11px !important;
  border-bottom-left-radius: 11px !important;
  box-shadow: 0px 6px 12px #0000004b !important;
  text-align: center !important;
}

.ant-card-head {
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  background-color: #061fcb !important;
}

#basic_Password {
  text-align: center !important;
}

#basic_Password {
  margin-top: 0 !important;
  border-radius: 13px !important;
  height: 25px !important;
  width: 270px !important;
}

.btn-submit {
  width: 200px !important;
  position: relative;
  right: 60px;
  font-family: "MavenPro-Bold";
  color: white !important;
  background: #e619a3 !important;
  border-radius: 27px !important;
}

.componente {
  max-width: 400px;
  text-align: center;
}

.login-campos {
  font-family: "MavenPro-Medium" !important;
  height: 35px !important;
  /* width: 270px !important; */
  border: 1px solid grey;
  border-radius: 13px !important;
  text-align: center !important;
  /* margin-left: 40px !important; */
  width: 80% !important;
}

.login-btn {
  width: 50% !important;
  font-family: "MavenPro-Bold";
  color: white !important;
  background: #e619a3 !important;
  border-radius: 27px !important;
}

.login-col {
  height: fit-content !important;
}

.login-row {
  height: 100%;
}

.ojo-pass {
  position: absolute !important;
  right: 15% !important;
  top: 28% !important;
}

@media screen and (max-width: 767px) {
  .login-row {
    height: fit-content !important;
  }

  .componente {
    max-width: 85%;
    text-align: center;
  }
}

/* @media (max-width: 575px) {
  .componente {
    position: absolute;
    left: 0%;
    top: 35%;
    width: 350px;
    padding: 0px;
  }
  .cont-button-login{

  }
} */

/* // Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {
  .componente {
    position: absolute;
    left: 20%;
    top: 35%;
    width: 400px;
    padding: 0px;
  }
} */

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {

  .componente {
    position: absolute;
    left: 30%;
    top: 35%;
    width: 400px;
    padding: 0px;
  }
} */

/* // Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {
  .componente {
    position: absolute;
    left: 60%;
    top: 35%;
    width: 400px;
    padding: 0px;
  }
} */

/* // Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { 
  .componente {
    position: absolute;
    left: 60%;
    top: 35%;
    width: 400px;
    padding: 0px;
  }
} */
