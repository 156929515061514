.subir_excel_feriado {
    width: 189px !important;
    height: 53px !important;
    font-family: MavenPro-bold;
    color: #ffffff !important;
    background-color: #29BA6C !important;
    border-color: #29BA6C;
    border-radius: 16px !important;
    text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}