@font-face {
  font-family: "MavenPro-Bold";
  src: url("../../fonts/MavenPro-Bold.ttf");
}

@font-face {
  font-family: "MavenPro-SemiBold";
  src: url("../../fonts/MavenPro-SemiBold.ttf");
}

@font-face {
  font-family: "MavenPro-Regular";
  src: url("../../fonts/MavenPro-Regular.ttf");
}

.tarjetaReporteNecesidades {
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.labelTipo {
  color: #262e2d;
  font-family: "MavenPro-SemiBold";
  font-size: 16px;
  padding-bottom: 2%;
}

.labelContenido {
  color: #262e2d;
  font-family: "MavenPro-Regular";
  font-size: 16px;
  padding-bottom: 2%;
}

.botonDetalle {
  background-color: #ffffff;
  border-radius: 6px !important;
  border: 1px #5c5d5f solid;
  color: #5c5d5f;
  font-family: "MavenPro-SemiBold" !important;
  font-size: 16px !important;
  width: 120px !important;
}

.modalDetalleActividadNecesidades {
  width: 75% !important;
  border-radius: 20px !important;
  opacity: 1 !important;
}

.modalDetallePlazaNecesidades {
  width: 70% !important;
  border-radius: 20px !important;
  opacity: 1 !important;
}

.tarjetaModalDetalleActividad1 {
  border: 1px solid #c7c7c7 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  padding-bottom: 18px !important;
  margin-top: 12px !important;
  margin-bottom: 15px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.tarjetaModalDetalleActividad2 {
  padding: 0px;
  border: 1px solid #c7c7c7 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  margin-bottom: 15px !important;
  width: 100%;
  height: 100%;
}

.encabezadoTarjetaModalDetalleActividad2 {
  margin-bottom: 2%;
}

.tarjetaModalDetallePlaza1 {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  padding-bottom: 18px !important;
  margin-top: 12px !important;
  margin-bottom: 15px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.tarjetaModalDetallePlaza2 {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  margin-bottom: 15px !important;
}

.tarjetaModalDetallePlaza3 {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;

  margin-bottom: 15px !important;
}

.trabajadores-cards-row {
  height: 250px !important;
  overflow-y: scroll !important;
}

.contenidoTarjetaModalDetalleActividad2 {
  height: 250px;
  width: 100%;
  overflow: scroll;
  /* overflow: auto; */
  /* white-space: nowrap; */
}

.contenidoTarjetaModalDetalleActividad2::-webkit-scrollbar {
  width: 5px;
}

.contenidoTarjetaModalDetalleActividad2::-webkit-scrollbar-thumb {
  background: #48b9fd;
}

.tarjetaReporteEspecificoNecesidadesPlaza {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  height: 100%;
}

.tarjetaPersonasDisponibleNecesidades {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  height: 100%;
}

.tarjetaGenteDisponible {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 21px !important;
  opacity: 1 !important;
  margin-left: 2%;
  margin-right: 2%;
}

.botonDetalleGenteDisponible {
  background-color: #ffffff;
  border-radius: 10px !important;
  border: 1px #5c5d5f solid;
  color: #5c5d5f;
  font-family: "MavenPro-Bold";
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.botonAsignarPlazaGenteDisponible {
  background-color: #52d68f;
  border-radius: 10px !important;
  border: 1px #52d68f solid;
  color: #ffffff;
  font-family: "MavenPro-Bold";
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.numeroGenteDisponible {
  color: #262e2d;
  font-family: "MavenPro-SemiBold";
  font-size: 16px;
}

.res-trabajador-img-wrapper {
  position: relative !important;
  width: 80% !important;
  padding-bottom: 100% !important;
}

.res-trabajador-img {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  border-radius: 50% !important;
}

.stepReporteNecesidadesPorCargo .ant-steps-item-content{
  width: 160px;
}

.stepReporteNecesidadesPorCargo .ant-steps-item-title{
  width: 100%;
  font-size: 12px;
}

.stepReporteNecesidadesPorCargo .ant-steps-item-description{
  width: 100%;
  text-align: center;
}

@media (max-width: 1650px) {
  .modalDetalleActividadNecesidades {
    width: 100% !important;
  }

  .modalDetallePlazaNecesidades {
    width: 100% !important;
  }

  .contenedortarjetaModalDetalleActividad2 {
    overflow-x: scroll;
  }
}

@media (max-width: 1450px) {
  .modalDetalleActividadNecesidades {
    width: 100% !important;
  }

  .modalDetallePlazaNecesidades {
    width: 100% !important;
  }

  .tarjetaModalDetalleActividad1 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  .tarjetaModalDetallePlaza1 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  .tarjetaModalDetalleActividad2 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  /* .contenedorTrabajadores{
    width: 100% !important;
  } */

  .botonDetalleGenteDisponible {
    font-size: 14px;
    margin-bottom: 5%;
    width: 100%;
  }

  .botonAsignarPlazaGenteDisponible {
    font-size: 14px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .modalDetalleActividadNecesidades {
    width: 100% !important;
  }

  .modalDetallePlazaNecesidades {
    width: 100% !important;
  }

  .tarjetaModalDetalleActividad1 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  .tarjetaModalDetallePlaza1 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  .tarjetaModalDetalleActividad2 {
    width: 100% !important;
    /* overflow: scroll !important; */
  }

  /* .contenedortarjetaModalDetalleActividad2 {
    overflow: scroll;
  } */

  .encabezadoTarjetaModalDetalleActividad2 {
    width: 650px !important;
  }

  .contenidoTarjetaModalDetalleActividad2 {
    width: 650px !important;
  }

  .botonDetalleGenteDisponible {
    font-size: 14px;
    margin-bottom: 5%;
    width: 100%;
  }

  .botonAsignarPlazaGenteDisponible {
    font-size: 14px;
    width: 100%;
  }

  .numeroGenteDisponible {
    font-size: 14px;
  }
}
