
/* btn filtrar */
.btn-filtrar-perfil-trabajador{
    /* width: 156px !important;
    border-radius:10px !important;
    font-family: "MavenPro-Bold" !important;
    font-size:14px !important;
    text-align:center !important;
    letter-spacing:0px !important;
    color: #ffffff !important;
    background: #48b9fd 0% 0% no-repeat padding-box !important;
    opacity: 0px !important;
    margin-top:25px !important; */
    /* margin-left: 10px !important; */
    width: 156px;
    text-align: center;
    font-family: MavenPro-Bold;
    letter-spacing: 0px;
    font-size: 14px;
    color: #ffffff;
    background: #48b9fd 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 25px;
    opacity: 1;
}

.card-leyenda-perfil-trabajador{
    height: 130px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    box-shadow: 0px 3px 6px #00000029 !important;text-align: center !important;
    opacity: 1 !important;
}

.title-leyenda-perfil-trabajador{
    font-family: "MavenPro-SemiBold" !important;
    /* margin-top: 30px !important; */
    margin-top: 50px !important;
    margin-left: 3px !important;
}

.col-leyenda-perfil-trabajador{
    font-family: "MavenPro-Regular" !important;
    margin-top: 30px !important;
    margin-left: 10px !important;
    /* margin: 5px !important; */
    /* margin-left: 10px !important; */
    text-align: center !important;
}

.card-perfil-trabajador{
    font-family: "MavenPro-Bold" !important;
    background: #F4F4F4 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
}

.card-perfil-trabajador .ant-card-head{
    background-color: #FFFFFF !important;
    border: 1px solid #E2E2E2 !important;
}

.card-title-perfil-trabajador{
    font-family: "MavenPro-Regular" !important; 
    font-size: 16px !important;
    color: #262E2D !important;
}

.card-img-perfil-trabajador{
    border-radius: 50% !important;
    width: 167px !important;
    height:167px  !important;
}

.card-detalle-perfil-trabajador{ 
    background: #F4F4F4 0% 0% no-repeat padding-box !important;
    width: 95% !important;
    height: 82px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    margin: 10px !important;
    opacity:1 !important;
}

.card-detalle-otras-condiciones{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    opacity: 1 !important;
}

.card-otras-condiciones{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    opacity: 1 !important;
    width: 270px !important;
    margin-bottom: 1rem;
}

.h4-cantidad-dias-otras-condiciones{
    color: #061FCB !important;
    font-family: "MavenPro-Bold" !important;
    font-size: 20px !important;
    margin-bottom: 0em !important;
}

.h3-detalle-perfil-trabajador{ 
    font-family: "MavenPro-SemiBold" !important;
    text-align: left !important;
    font-size: 15px !important;
    /* color: #262e2d !important; */
    /* margin-left: 3px !important; */
    padding-left: 0.5rem !important;

}

.h3-padding-dpt{
    margin-top: -15px !important;
}


/* CALENDARIO TRABAJADOR */
.card-timeline-perfil-trabajador{ 
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    margin-top: 25px !important;
    /* margin: 2px !important; */
    opacity: 1 !important;
}

.card-timeline-perfil-trabajador .ant-card-head{
    background-color: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
}

.card-title-timeline-perfil-trabajador{ 
    font-family: "MavenPro-SemiBold" !important; 
    font-size: 16px !important;
    text-align: left !important;
    color: #061FCB !important;
}

.card-subtitle-timeline-perfil-trabajador{ 
    font-family: "MavenPro-SemiBold" !important; 
    font-size: 14px !important;
    text-align: left !important;
    color: #000000 !important;
}

/* INDICADORES */
.card-indicadores-perfil-trabajador{ 
    width: 320px !important;
    height: 162px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 16px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    margin-top: 15px !important;
    margin-left: 0.2rem !important;
    /* margin: 1px !important; */
    opacity: 1 !important;
}

.card-img-indicadores-perfil-trabajador{ 
    width: 77px !important;
    height: 77px !important;
    margin: 20px !important;
}

.card-h1-indicadores-perfil-trabajador{ 
    font-family: "MavenPro-SemiBold" !important;
    color: #061FCB !important;
    font-size: 25px !important;
    margin-bottom: -10px !important;
    margin-left: 10px !important;
}

.card-p-indicadores-perfil-trabajador{ 
    font-family: "MavenPro-SemiBold" !important;
    color: #061FCB !important;
    font-size: 14px !important;
    margin-left: 10px !important;
}


.tail-green-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #52D68F !important;
}

.tail-pink-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #F296F5 !important;
}

.tail-lightpurple-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #7C8DFC !important;
}

.tail-cyan-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #3CADF0 !important;
}
.tail-electric-blue-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #5C8CDF !important;
}

.tail-yellow-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #EFBF20 !important;
}

.tail-orange-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #EF7720 !important;
}
.tail-violet-timeline .ant-steps-item-tail {
    border-bottom: 3px solid #8B437D !important;
}

/* Modal Style */
.modal-perfil-trabajador {
    /* width: 80% !important; */
    border-radius: 16px !important;
    padding: 5px !important;
    background-color: #FFFFFF !important;
}

.modal-perfil-trabajador .ant-modal-content{
    box-shadow: none !important;
}

.modal-detalle-otras-condiciones {
    border-radius: 16px !important;
    padding: 5px !important;
    background-color: #FFFFFF !important;
}

.modal-detalle-otras-condiciones .ant-modal-content{
    box-shadow: none !important;
}

/* COLORE TIMELINE */
.dot-green-timeline .ant-steps-icon-dot {
    /* TrabajoEfectivo */
    background: #52D68F !important;
}
.dot-pink-timeline .ant-steps-icon-dot {
    /* Descanso */
    background: #F296F5 !important;
}
.dot-lightpurple-timeline .ant-steps-icon-dot {
    /* EnTransito */
    background: #7C8DFC !important;
}
.dot-cyan-timeline .ant-steps-icon-dot {
    /* Vacaciones */
    background: #3CADF0 !important;
}
.dot-electric-blue-timeline .ant-steps-icon-dot {
    /* Licencia */
    background: #5C8CDF !important;
}
.dot-yellow-timeline .ant-steps-icon-dot {
    /* LicenciaMutualidad */
    background: #EFBF20 !important;
}
.dot-orange-timeline  .ant-steps-icon-dot {
    /* Permiso */
    background: #EF7720 !important;
}
.dot-violet-timeline .ant-steps-icon-dot {
    /* Ausente */
    background: #8B437D !important;
}
.dot-red-timeline .ant-steps-icon-dot {
    /* NoAsignado */
    background: #E64040 !important;
}


@media only screen and (max-width: 1650px) {

}
@media only screen and (max-width: 1450px) {
    .card-leyenda-perfil-trabajador{
        margin-top: 25px !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 1350px) {
    .card-otras-condiciones{
        width: 245px !important;
    }
}

@media only screen and (max-width: 1200px) {

    .card-contenedor-otras-condiciones{
        display: flex;
        justify-content: center
    }

    .card-otras-condiciones{
        text-align: center;
        width: 220px !important;
    }
}

@media only screen and (max-width: 900px) {
    /* ultimo cambio css seba */
    .title-leyenda-perfil-trabajador{
        margin-top: 15px !important;
    }
    .card-leyenda-perfil-trabajador{
        margin-top: 25px !important;
        height: auto !important;
    }
    .col-leyenda-perfil-trabajador{
        margin: auto !important;
    }
    .card-detalle-perfil-trabajador{
        margin: auto !important;
        width: 210px !important;
        margin-top: 15px !important;
    }
    .h3-detalle-perfil-trabajador{ 
        text-align: center !important;
    }
    .h3-padding-dpt{
        margin-top: -15px !important;
    }
    /* INDICADORES */
    .card-indicadores-perfil-trabajador{ 
        width: 320px !important;
        margin-top: 15px !important;
    }
}
@media only screen and (max-width: 700px) {
    .card-leyenda-perfil-trabajador{
        margin-top: 25px !important;
        height: auto !important;
    }
    .col-leyenda-perfil-trabajador{
        margin: auto !important;
    }
    .card-detalle-perfil-trabajador{
        margin: auto !important;
        width: 210px !important;
        margin-top: 15px !important;
    }
    .h3-detalle-perfil-trabajador{ 
        text-align: center !important;
    }
    .h3-padding-dpt{
        margin-top: -15px !important;
    }
    /* INDICADORES */
    .card-indicadores-perfil-trabajador{ 
        width: 340px !important;
        margin-top: 15px !important;
    }
}

@media only screen and (max-width: 500px) {
    .card-leyenda-perfil-trabajador{
        margin-top: 25px !important;
        height: auto !important;
    }
    .col-leyenda-perfil-trabajador{
        margin: auto !important;
    }
    .card-detalle-perfil-trabajador{
        margin: auto !important;
        width: 210px !important;
        margin-top: 15px !important;
    }
    .h3-detalle-perfil-trabajador{ 
        text-align: center !important;
    }
    .h3-padding-dpt{
        margin-top: -15px !important;
    }
    /* INDICADORES */
    .card-indicadores-perfil-trabajador{ 
        text-align: 340px !important;
        margin-top: 15px !important;
    }
}

/* @media only screen and (max-width: 430px) {
    .card-otras-condiciones{
        width: 120px !important;
    }
} */

@media only screen and (max-width: 420px) {
    .card-otras-condiciones{
        width: 170px !important;
    }
}

