.codigos-card {
  border-radius: 10px;
  background-color: #f5f5f5 !important;
  border: 1px solid #e6e6e6;
}

.codigo-wrapper {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background-color: white !important;
  padding: 5px;
}

.input-otro {
  width: 120px !important;
  /* position: absolute !important;
  right: 0px; */
}

.info-epps-icon {
  font-size: 16px !important;
  cursor: pointer !important;
  text-align: right !important;
  margin-right: 5px !important;
}