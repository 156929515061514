.codigos-card {
  border-radius: 10px;
  background-color: #f5f5f5 !important;
  border: 1px solid #e6e6e6;
}

.codigo-wrapper {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background-color: white !important;
  padding: 5px;
}

.input-otro {
  width: 120px !important;
  /* position: absolute !important;
  right: 0px; */
}

.imprimir-space {
  text-align: center !important;
}

.button-center {
  text-align: center !important;
}
