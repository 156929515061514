.card_content--text {
  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 122px;
  padding-right: 122px;
  color: #061fcb;
  font-family: "MavenPro-Bold";
}

.formDescanso_card_title {
  font-size: 21px;
  font-family: "MavenPro-Bold";
  color: #061fcb;
  padding-top: 10px;
  margin-left: 18px;
}

.inputsFormDescanso_Text {
  font-family: "MavenPro-Regular";
}

.descansoBtn_guardar {
  margin-left: 50%;
}

.guardar-form-fecha-fin {
  width: 189px !important;
  height: 35px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #e60aa3 !important;
  border-radius: 16px !important;
  border-color: #e60aa3;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .card_content--text {
    padding-left: 53px;
    padding-right: 53px;
  }
  .descansoBtn_guardar {
    margin-left: 61%;
  }
}
