.fix {
  margin-top: -10vh;
}

.guardar-vacaciones-button {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.editar-vacaciones-button {
  background-color: #48b9fd;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.set-invisible {
  display: none !important;
}

.editando-block {
  font-family: MavenPro-SemiBold;
  color: #48b9fd;
  
  border: 1px solid #48b9fd !important;
  border-radius: 3px !important;

  text-align: center !important;
  padding: 5px;
}
