.texto-resultados-detalle-costos{
    color: #061FCB;
    font-family: 'MavenPro-Bold';
}

.columna-trabajados-sistema{
    background-color: #D7F4F7;
}

.columna-trabajados-excel{
    background-color: #D7F7D8;
}