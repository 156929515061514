.title_resultado_filtro {
  color: #061fcb;
  margin-left: 20px;
  margin-top: 24px;
  font-family: "MavenPro-Bold";
  font-size: 20px;
}

.contenedor_personas_pendientes {
  margin: 10px 10px 10px 10px;
}

.crear-confirmar-asistencia-button {
  background-color: #29ba6c;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  height: 26px !important;
  padding: 4px 24px !important;
  font-size: 12px !important;
}

.title_estado_pendientes {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "MavenPro-ExtraBold";
  color: #262e2d;
}

.title_faena_pendientes {
  margin-top: 0px;
  font-family: "MavenPro-Regular";
  color: #262e2d;
  font-size: 13px;
}

.title_nombre_pendientes {
  font-family: "MavenPro-Bold";
  font-size: 12px;
  margin-bottom: 1px;
  color: #061fcb;
}

.nombre-asistencia-timeline {
  font-family: "MavenPro-ExtraBold";
  color: #262e2d;
  height: 15px;
  font-size: 15px;
}

.centro-costo-asistencia-timeline {
  font-family: "MavenPro-ExtraBold";
  color: #262e2d;
  height: 10px;
  font-size: 10px;
}

.asistencia-mobile {
  display: none !important;
}

.filtrar-asistencia-btn {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 140px;
}

.asistencia-card {
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;

  margin-bottom: 15px;

  padding: 8px;
}

.texto-card-asistencia {
  margin-bottom: 3px !important;
}

.validar-asistencia-btn {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 150px;
}


/* Nuevos */
.btn-asistencia-filtrar{
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #64B4E5  !important;
  border-radius: 9px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.btn-quitar-asistencia{
  font-family: MavenPro-bold;
  text-align: center;
  color: #ffffff !important;
  background-color: #64B4E5  !important;
  border-radius: 9px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  /* margin-left: -30px; */
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-quitar-asistencia-disabled {
  font-family: MavenPro-bold;
  text-align: center;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 9px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-pasar-asistencia{
  font-family: MavenPro-bold;
  text-align: center;
  color: #ffffff !important;
  background-color: #E619A3  !important;
  border-radius: 9px !important;
  border-color: #c5189a;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  /* margin-left: -10px; */
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-pasar-asistencia-disabled {
  font-family: MavenPro-bold;
  text-align: center;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 9px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.selec-form-asistencia-pasar {
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #E619A3  !important;
  border-radius: 9px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}
.selec-form-asistencia-quitar{
  font-family: MavenPro-bold;
  margin-right: 10px;
  color: #ffffff !important;
  background-color: #64b4e5  !important;
  border-radius: 9px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.selec-form-asistencia-pasar-disabled {
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 9px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.selec-form-asistencia-quitar-disabled {
  font-family: MavenPro-bold;
  margin-right: 10px;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 9px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.col-content-asistencia-first{
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E2E2E2;
  opacity: 1;
  display: inline-block;
}
.col-content-asistencia-second{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E2E2E2;
  opacity: 1;
  display: inline-block;
}
.col-p-asistencia-title-text {
  font-family: MavenPro-Bold;
  text-align: left;
  margin-left: 15px;
  margin-top: 10px;
  letter-spacing: 0px;
  /* color: #001fcb; */
  color: #000000;
  opacity: 1;
  margin-right: 60px;
}
.col-p-asistencia-subtitle-text {
  text-align: left;
  font-family: "MavenPro-Regular";
  color: #000000;
  opacity: 1;
 
}

.contenedor-step-asistencia div div .ant-steps-item-container{
  width: 35px !important;
}

.ant-icon-tamaño-asistencia .ant-steps-icon-dot {
  width: 12px !important;
  height: 12px !important;
  margin-top: 50px;
  margin-left: -50px;

}

.container-step-asistencia .ant-steps-item-container{
  /* margin-top: -20px; */
  margin-top: -5px;

}

.tail-step-asistencia .ant-steps-item-tail{
  /* padding nuevo */
  padding-top: 18px; 
  margin-top: 30px !important;
  /* margin-left: 20px; */
  margin-left: 21px;
}
.col-pagination-asistencia{
  margin-top: 10px;
  text-align: right;
}

.popover-btn-quitar{
    background: linear-gradient(90deg, #E619A3 50%, #64B4E5  50%);
    /* background: #64B4E5 0% 0% no-repeat padding-box; */
    border-radius: 20px;
    opacity: 1;  
}

.popover-btn-pasar{
  background: #E619A3 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;  
}

.popover-p-quitar-pasar{
    font-family: MavenPro-Bold;
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.swal2-footer{
  text-align: center;
}

/* .asis-popover-contenido{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E2E2E2;
  opacity: 1;
  display: inline-block;
} */



@media screen and (max-width: 799px) {
  .asistencia-desktop {
    display: none !important;
  }

  .asistencia-mobile {
    display: block !important;
  }
}
