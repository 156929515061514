.titulo-dashboard-dinamico{
    color: #061FCB;
    font-family: "MavenPro-Bold";
    font-size: 26px;
}

.sub-titulo-dashboard-dinamico{
    color: #061FCB;
    font-family: "MavenPro-Bold";
    font-size: 16px;
}