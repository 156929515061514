.card-actividad-modal {
    /* box-shadow: 0px 6px 12px #0000004b !important;
    border: 1px solid #e7e5e5; */
    text-align: center !important;
    line-height: 15px !important; 
    border: 0px;
}
.radio-group-modal{
    text-align: center !important;
    display: block !important;
    margin-left: 85px !important;
}

.act-modal-campos {
    border-radius: 8px !important;
    width: 50% !important;
    text-align: left;
}

.h3-cc-modal{
    text-align: left;
    color: #061FCB;
    font-family: "MavenPro-Bold";
}

.h3-act-modal{
    text-align: left;
    color: #000000;
    font-family: "MavenPro-Bold";
}


.table-rpicker{
    /* width: 100%; */
}
.col-faena-form{
    /* width: 40%; */
}

.col-icon-form{
    /* width: 10%; */
    margin-top: 100px;
    color: blue;
    font-size: 50px;
    display: block;
    text-align: center;
}

.table-actividad
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  /* padding-left: 52px !important; */
  margin: 0px 0px 0px 52px !important;
}
.table-actividad .ant-table-thead > tr > th {
    background: #fff !important;
    color: #262e2d !important;
}
@media only screen and (max-width: 767px) {

    /* .table-actividad .ant-table-thead > tr > th {
        width: 1000px !important;
    } */

    /* .texto-ajustado{
        font-size: 8px;
        margin: 0;
    } */
    /* .title-act-modal{
        font-size: 18px;
    } */
    .ant-form-item-label > label {
        font-size: 12px;
    }
    .ant-page-header-heading-title{
        font-size: 16px;
    }

    .act-modal-campos {
        border-radius: 8px !important;
        width: 100% !important;
        text-align: left;
    }

    .radio-group-modal{
        text-align: left !important;
        margin-left: -1px !important;
        margin: 0;
        opacity: 1;
    }
    .col-faena-form{
        /* width: 100% !important; */
    }

    .col-icon-form{
        margin-bottom: 100px;
        transform: rotate(90deg);
        width: 100%;
        color: violet;
        font-size: 50px;
    }
    .table-rpicker{
        /* width: 200% !important; */
    }

}