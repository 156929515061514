.btnGuardarT3{
    width: 156px;
    height: 41px;
    background:#64B4E5 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #FFFFFF;
    letter-spacing: 0px ;
    text-align: center ;
    font-family:'MavenPro-Bold';
}
.btnGuardarDisabledT3{
    width: 156px;
    height: 41px;
    background: #A3A3A3 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    color: #FFFFFF;
    letter-spacing: 0px ;
    text-align: center ;
    font-family:'MavenPro-Bold';
}

.selectRangepickerT3{
    width: '100%';
    border-radius: 8px;
}

.btnFiltrarT3{
    /* color:'#FFFFFF'; */
    color: #FFFFFF;
    text-align: 'center';
    font-family: 'MavenPro-Bold';
    width: 156px;
    height: 30px;
    background: '#64B4E5 0% 0% no-repeat padding-box';
    background:#64B4E5;
    border-radius: 9px;
    opacity: 1;
}
.btnFiltrarBloqT3{
    /* color:'#262E2D'; */
    text-align: 'center';
    font-family: 'MavenPro-Bold';
    width: 156px;
    height: 30px;
    background: '#A3A3A3 0% 0% no-repeat padding-box';
    border-radius: 9px;
    opacity: 1;
}

.cardLeyendaT3{
    background: '#ffffff 0% 0% no-repeat padding-box';
    border-radius: 16px;
    box-shadow: '0px 3px 6px #00000029';
    border:'5px solid #e2e2e2;';
    opacity:'1'; 
}

.titleLenyendaT3{
    font-family: 'MavenPro-Bold';
    margin-top: 18px;
    text-align: left;
}

.imgLeyendasAllT3{
    width: 35px ;
    height: 35px ;
    margin: auto;
    display: block;
}

.imgLeyendasVacioT3{
    border: 2px solid;
    border-color: #555;
    border-radius: 50%;
    background-color: #FFFFFF;
    width: 35px ;
    height: 35px ;
    margin: auto;
    display: block;
    opacity: 1;
}

.titleImgLenyendaT3{
    font-family: 'MavenPro-Regular';
    text-align: center;
    font-size: 14px;
    color: #000000;
}

.cardCalendarioT3{
    margin-top: 15px;
    background: #F4F4F4;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;
}

.h1TitleCalendario{

    /* <h1 style={{color:'#061FCB',fontFamily:'MavenPro-Bold'}}>Calendario del trabajador:</h1> */
}
.h1TitleCalendarioPers{
/* <h1 style={{fontFamily: 'MavenPro-Regular',color:'#000000'}}> */
}


.colTitleRecuentoT3{
    font-family: 'MavenPro-Bold';
    color:#000000;
    text-align: left;
}

.imgRecLeyendasAllT3{
    width: 28px ;
    height: 28px ;
    margin: auto;
    display: block;
}

.colCircleLeyendaT3{
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    border-radius: 50%;
    border: 2px solid;
    border-color: #555;
    margin: auto;
    display: block;
    opacity: 1;
    /* style={{width:'25px',height:'25px',borderRadius: '50%',border:'2px solid #555',background:'white',margin:'auto',display:'block'}} */
}

.colCantDiasT3{
    font-family: 'MavenPro-Regular';
    text-align: center;
    font-size: 14px;
    color: #000000;
}

.cardCalendarioBitT3{
    border: 1px solid #e2e2e2;
    border-radius: 16px;
}

.colMsjSelecionarT3{
    font-family: 'MavenPro-Bold';
    background: #FFFFFF;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    /* style={{fontFamily: 'MavenPro-Bold',background:'white',border:'1px solid #e2e2e2',borderRadius:"16px"}} */
}

.cardHistorialT3{
    /* style={{background:'#F4F4F4',border:'1px solid #e2e2e2',borderRadius:"16px",boxShadow:'0px 3px 6px #00000029',marginTop:'15px'}} */
    margin-top: 15px;
    background: #F4F4F4;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;

}

.colTitleHistorialT3{
    color: #061FCB;
    font-family: 'MavenPro-Bold';
    text-align: left;
    /* style={{ color:'#061FCB',fontFamily:'MavenPro-Bold',textAlign:'left' }} */
}


.cardArrayHistorialT3{
    /* background:'#ffffff 0% 0% no-repeat padding-box;',
    borderRadius:'16px',
    boxShadow:'0px 3px 6px #00000029;',
    border:'5px solid #e2e2e2;',
    opacity:'1',  
    marginTop:'10px' */
    margin-top: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 5px solid #e2e2e2; */
    
}


.acciones-trabajador-button {
    border-radius: 12px;
    box-shadow: 0px 1px 3px #0141d074;
    border: none;
    width: fit-content !important;
    padding: 0px 10px !important;
    height: 34px;
}
.btnActionT3 {
    border-radius: 12px;
    box-shadow: 0px 1px 3px #0141d074;
    border: none;
    width: fit-content !important;
    padding: 0px 10px !important;
    height: 34px;
}

.hover-azul:hover {
    background-color: #0141d074;
    color: white;
}
  
.hover-celeste:hover {
    background-color: #64b4e5;
    color: white;
}
  
.hover-rojo:hover {
    background-color: #e61958;
    color: white;
}

  @media only screen and (max-width: 767px) {
    .selectRangepickerT3{
        margin-top: 15px;
        width: '100%';
        border-radius: 8px;
    }
    
    .btnFiltrarT3{
        margin-top: 15px;
        color: #FFFFFF;
        text-align: 'center';
        font-family: 'MavenPro-Bold';
        width: 156px;
        height: 30px;
        background: '#64B4E5 0% 0% no-repeat padding-box';
        background:#64B4E5;
        border-radius: 9px;
        opacity: 1;
    }

    .cardLeyendaT3{
        display: none;
    }


  }