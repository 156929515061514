.btn-filtrar-calendar {
  width: 156px;
  text-align: center;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  font-size: 14px;
  color: #ffffff;
  background: #48b9fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.ant-badge-color-white .ant-badge-status-dot{
  background-color:#FFFFFF;
    border:2px solid #707070;    
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    color: #262e2d;
}


.ant-modal-crear-actividad .ant-modal-content {
  border-radius: 16px !important;
}

/* todo editar */
.ant-modal-editar-actividad .ant-modal-content {
  border-radius: 16px !important;
}
.btn-switch-checked .ant-switch-checked {
  background-color: #2BE227;
}
/* todo editar */

/* todas las columnas */
.col-pagination{
  margin-top: 10px;
  text-align: right;
}
.col-content-porcentaje-sin-planificar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e2e2e2;
  opacity: 1;
  display: inline-block;
}
.col-p-porcentaje-sin-planificar-title {
  margin-top: 10%;
  text-align: center;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #001fcb;
  opacity: 1;
}
.col-p-porcentaje-sin-planificar {
  text-align: center;
  font-family: MavenPro-Bold;
  font-size: 40px;
  letter-spacing: 0px;
  color: #e64040;
  opacity: 1;
}

.col-content-grafico {
  text-align: center;
  margin-top: 4%;
  margin-bottom: auto;
}

.btn-editar-actividad {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-editar-actividad :disabled {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}


.btn-detalle-actividad {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-detalle-actividad :disabled {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-img-vista{
  width: 20px;
  opacity: 0.5;
}

.btn-eliminar-actividad {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-eliminar-actividad :disabled {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-actualizar-actividad {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.btn-actualizar-actividad :disabled {
  width: 50px;
  height: 42px;
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #5c5d5f;
  border-radius: 10px;
  opacity: 1;
}

.col-p-actividad-fecha-text {
  font-family: MavenPro-Regular;
  letter-spacing: 0px;
  color: #262e2d;
  opacity: 1;
}
.col-p-actividad-location-text {
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #262e2d;
  opacity: 1;
}
.col-p-actividad-title-text {
  font-family: MavenPro-Bold;
  letter-spacing: 0px;
  color: #001fcb;
  opacity: 1;
}
.col-content-actividad-align{
      margin-top: 1%;
      margin-left: 1%;
      margin-bottom: 2%;
      text-align: left;
}
.col-content-actividad{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E2E2E2;
  opacity: 1;
  display: inline-block;
}
.btn-crear-actividad{
      width: 156px;
      text-align: center;
      font-family: MavenPro-Bold;
      letter-spacing: 0px;
      font-size: 14px;
      color: #FFFFFF;
      background: #E60AA3 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
}

.p-filtrar-plane-text{
    font-family: MavenPro-Regular;
    font-size: 14px;
    margin-bottom: 3px;
}

/* -------- */
.guardar-formX {
  width: 156px !important;
  text-align: center;
  letter-spacing: 0px;
  font-size: 18;
  font-family: MavenPro-bold;
  opacity: 1;
  color: #ffffff !important;
  background: #e60aa3 0% 0% no-repeat padding-box;
  background-color: #e60aa3 !important;
  border-radius: 10px !important;
  border-color: #e60aa3;

  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.guardar-form-disabledX {
  width: 156px !important;
  text-align: center;
  letter-spacing: 0px;
  font-size: 18;
  font-family: MavenPro-bold;
  opacity: 1;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 10px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}
/* Distancia de puntos */
.contenedor-step-asig-act div div .ant-steps-item-container {
  width: 35px !important;
  /* estaba en 40px */
}
.col-createAct {
  text-align: right;
}
.ant-leyenda .ant-badge-status-text {
  display: flex;
  margin-top: -30px;
  margin-left: 25px;
}
.ant-leyenda-dot .ant-badge-status-dot {
  margin-bottom: 1px;
}

/* .ant-steps-item-container .ant-steps-item-tail{background-color: blueviolet !important;} */
/* //Tamaño de puntos */
.ant-icon-tamaño .ant-steps-icon-dot {
  width: 12px !important;
  height: 12px !important;
  margin-top: 50px;
  /* ajuste timeline */
  margin-left: -50px;
}

.testContainer .ant-steps-item-container{
  margin-top: -20px;
  
}

/* poner una clase extra */
.testTail .ant-steps-item-tail{
  margin-top: 30px !important;
  /* margin-bottom: 20px !important; */
  /* ajuste timeline */

  margin-left: 20px;
}

/* .ant-steps-item-title{
  bottom:50px
} */
/* .ant-steps-item-content{
  background-color: #262e2d !important;
  padding-top: 50px;
  padding-bottom: 50px;
} */
.dot-purple .ant-steps-icon-dot {
  background: purple !important;
}
.dot-blue .ant-steps-icon-dot {
  background: #7c8dfc !important;
}
.dot-green .ant-steps-icon-dot {
  background: #52d68f !important;
}

.dot-blue-special .ant-steps-icon-dot {
  background: #7c8dfc !important;
  cursor: pointer;
}
.dot-green-special  .ant-steps-icon-dot {
  background: #52d68f !important;
  cursor: pointer;
}

.dot-blue-special .ant-steps-icon-dot:hover {
  background: #E619A3 !important;
  cursor: pointer;
  animation: 2s ease 0s infinite normal none running pulse-blue;
  /* width: 30px;
    height: 30px;
    background-color: rgb(0, 156, 222);
    border-radius: 30px;
    margin-left: -16px;
    margin-top: -10px;
    cursor: pointer; */
}
.dot-green-special  .ant-steps-icon-dot:hover {
  background: #E619A3 !important;
  cursor: pointer;
  animation: 2s ease 0s infinite normal none running pulse-blue;
  /* width: 30px;
    height: 30px;
    background-color: rgb(0, 156, 222);
    border-radius: 30px;
    margin-left: -16px;
    margin-top: -10px;
    cursor: pointer; */
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #E619A3;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}
.dot-yellow .ant-steps-icon-dot {
  background: #fad868 !important;
}
.dot-red .ant-steps-icon-dot {
  background: #e64040 !important;
}
.dot-white .ant-steps-icon-dot {
  border: 2px solid #707070;
  border-radius: 2px;
  color: #262e2d;
  background: #FFFFFF !important;
}
.dot-grey .ant-steps-icon-dot {
  background: #A3A3A3 !important;
}
.dot-cyan .ant-steps-icon-dot {
  background: #3CADF0 !important;
}
.dot-violet .ant-steps-icon-dot {
  background: #7C8DFC !important;
}
.dot-orange .ant-steps-icon-dot {
  background: #EF7720 !important;
}

.ant-steps-item-title {
  font-size: 16px;
  color: #262e2d !important;
  /* margin-left: 5px; */
  /* ajuste timeline */
  margin-right: 95px;
}

.ant-steps-item-title-month .ant-steps-item-title {
  padding-top: 40px !important;
}

.step-fecha-inicio-fin-mes .ant-steps-icon-dot{
  display: none;
}

.step-fecha-inicio-fin-mes .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  display: none;
}

.step-fecha-inicio-fin-mes .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  display: none;
}


/* .ant-steps-icon-dot { background: red !important; } */

@media only screen and (max-width: 767px) {
  .ant-leyenda-dot .ant-badge-status-dot {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 991px) {
  .col-filtrarAct {
    margin-top: 3%;
  }
  .col-createAct {
    margin-top: 3%;
    text-align: left;
  }


}
