.card_content--text {
  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 122px;
  padding-right: 122px;
  color: #061fcb;
  font-family: "MavenPro-Bold";
}

.formDescanso_card_title {
  font-size: 21px;
  font-family: "MavenPro-Bold";
  color: #061fcb;
  padding-top: 10px;
  margin-left: 18px;
}

.inputsFormDescanso_Text {
  font-family: "MavenPro-Regular";
}

.descansoBtn_guardar {
  margin-left: 50%;
}

.popover-icon {
  display: inline-block !important;
  width: 20px !important;
}

/*.highlight {
  box-shadow: 0px 0px 5px rgba(255, 115, 0, 0.5) !important;
} */

.d-none {
  display: none !important;
}

/* table tr:last-child td:first-child {
  border-bottom-left-radius: 10px !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px !important;
} */

.pop-table {
  border-collapse: separate !important;
  border-spacing: 0px !important;
  font-family: "MavenPro-Regular" !important;
}

.pop-tr:first-child .pop-td:first-child {
  border-top-left-radius: 10px !important;
}
.pop-tr:first-child .pop-td:last-child {
  border-top-right-radius: 10px !important;
}
.pop-tr:last-child .pop-td:first-child {
  border-bottom-left-radius: 10px !important;
}
.pop-tr:last-child .pop-td:last-child {
  border-bottom-right-radius: 10px !important;
}

.pop-td-first {
  border-top: 1px solid #48b9fd !important;

  padding: 15px !important;
  font-family: "MavenPro-Regular";
}

.pop-td-med {
  border-top: 1px solid #48b9fd !important;
  border-bottom: 1px solid #48b9fd !important;

  padding: 15px !important;
  font-family: "MavenPro-Regular";
}

.pop-td-last {
  border-bottom: 1px solid #48b9fd !important;

  padding: 15px !important;
  font-family: "MavenPro-Regular";
}

.td-left {
  border-left: 1px solid #48b9fd !important;
  text-align: right !important;
}

.td-right {
  border-right: 1px solid #48b9fd !important;
  position: relative !important;
  text-align: center;
}

.short-border {
  background-color: #48b9fd !important;
  width: 1px !important;

  position: absolute !important;
  left: 0px !important;
  top: 6px !important;
  bottom: 7px !important;
}

.t-bold {
  font-weight: 900 !important;
}

.detalle {
  position: absolute !important;
  bottom: 30px !important;
  right: 15px !important;
}

.detalle-trabajador {
  font-family: "MavenPro-Regular" !important;
  width: fit-content !important;
  margin-left: auto !important;

  border: 1px solid #cccccc !important;
  border-radius: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.detalle-div {
  text-align: right !important;
}

.otorgados-invalid {
  color: rgba(255, 50, 50, 1) !important;
}

.otorgados-invalid-input {
  border: 1px solid rgba(255, 50, 50, 0.5) !important;
  box-shadow: 0px 0px 7px rgba(255, 50, 50, 0.5) !important;
}

.info-icon {
  /* color: #e60aa3; */
  font-size: 18px !important;
  cursor: pointer !important;

  position: absolute !important;
  top: 7px !important;
  right: 10px !important;
}

.info-text {
  text-align: left !important;
  font-family: "MavenPro-Regular" !important;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .card_content--text {
    padding-left: 53px;
    padding-right: 53px;
  }
  .descansoBtn_guardar {
    margin-left: 61%;
  }
}
