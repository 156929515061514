/*CSS HEADER CALENDARIO TIME LINE*/
.react-calendar-timeline .rct-dateHeader-primary {
  background-color: white;
  color: #a9a6a6;
}

.react-calendar-timeline .rct-dateHeader {
  border-left: 0px;
}

.custom-group {
  height: 100px;
}

.custom-group-narrow {
  height: 70px !important;
}

.header-plaza {
  font-family: "MavenPro-Bold";
  font-size: 16px;
}

.react-calendar-timeline .rct-header-root {
  background: white;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #969696;
  box-sizing: border-box;
  z-index: 40;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  z-index: 40;
}

.img-icon {
  width: 31px;
  height: 31px;
}

.campo-plaza {
  font-family: "MavenPro-Bold";
  font-size: 12px;
}

.cont-plaza {
  padding-left: 50px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 0px;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: white;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: white;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgb(255 255 255 / 50%);
}

.barra-color {
  height: 30px !important;
}
/*CSS TABLA DE LINEAS*/

/*COLORES*/

.item-gris {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #969696 !important;
  border: 1px solid #969696 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-celeste {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #7c8dfc !important;
  border: 1px solid #7c8dfc !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-celeste-claro {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #3CADF0 !important;
  border: 1px solid #3CADF0 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-verde {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #52d68f !important;
  border: 1px solid #52d68f !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-amarillo {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #efc12b !important;
  border: 1px solid #efc12b !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-amarillo-claro {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #fad868 !important;
  border: 4px solid #e7c658 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-rojo {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #e64040 !important;
  border: 1px solid #e64040 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-morado {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #a709a7 !important;
  border: 1px solid #a709a7 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-descanso-achurado {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  /* background-color: #3CADF0 !important;
  border: 1px solid #3CADF0 !important; */
  background: -moz-repeating-linear-gradient(45deg, #2988C1, #2988C1 6px, #3CADF0 2px, #3CADF0 18px)!important;
  background: -webkit-repeating-linear-gradient(45deg, #2988C1, #2988C1 6px, #3CADF0 2px, #3CADF0 18px)!important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-naranjo {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #ef7720 !important;
  border: 1px solid #ef7720 !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}

.item-amarillo-diaRelevo {
  font-size: 12px !important;
  color: white !important;
  cursor: pointer !important;
  background-color: #d5a60c !important;
  border: 1px solid #d5a60c !important;
  z-index: 80 !important;
  position: absolute !important;
  box-sizing: border-box !important;
}
