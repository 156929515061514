
.title-re .ant-card-head-title{
    font-family: MavenPro-Bold !important;
    color:#061FCB !important;
    text-align: left !important;
    font-size: 14px !important;    
    opacity: 1;
}

.card-re-head .ant-card-head{
    background-color: #FFFFFF !important;
    opacity: 1;
}

.card-report-excel{
    width: 300px;
    height: 155px;
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E2E2E2;
    border-radius: 16px;
    opacity: 1;
    /* marginLeft:'10px', textAlign:'left', boxShadow:'0px 3px 6px #00000029',border:'1px solid #E2E2E2',borderRadius:'16px',opacity:'1' */
}

.div-report-excel{
    text-align: right;
    cursor: pointer;
}
.img-report-excel{
    width: 50px;
    height: 39px;
    opacity: 1;
}


.p-report-excel{
    margin-top: -10px;
    /* margin:-1px; */
    font-size: 14px;
    text-align: left;
    font-family: MavenPro-Bold;
    opacity: 1;
}

.h2-report-excel{
    margin-top: -5px;
    font-size: 14px;
    text-align: left;
    font-family: MavenPro-Regular;
    opacity: 1;
}
.cont-reportes-excel{
  cursor: pointer;
}
.cont-reportes-excel:hover{
    -webkit-box-shadow: 13px 12px 8px -5px rgba(0,0,0,0.54); 
    box-shadow: 13px 12px 8px -5px rgba(0,0,0,0.54);
}
.modal-repotes-excel-btn{
    background-color: #e60aa3;
    border-radius: 16px;
    width: 100px;
    height: 32px;
    color: #FFFFFF;
    font-family: MavenPro-Bold;
    opacity: 1;
}

.modal-repotes-excel .ant-modal-content {
    border-radius: 16px !important;
    margin-top: 120px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;

}
@media only screen and (max-width: 767px) {

    /* .modal-repotes-excel{
        margin-top: 120px;
        background-color: rgb(30, 104, 146);
        opacity: 1;
    } */
}
