@font-face {
  font-family: "MavenPro-Bold";
  src: url("../../fonts/MavenPro-Bold.ttf");
}

@font-face {
  font-family: "MavenPro-SemiBold";
  src: url("../../fonts/MavenPro-SemiBold.ttf");
}

@font-face {
  font-family: "MavenPro-Regular";
  src: url("../../fonts/MavenPro-Regular.ttf");
}

.select-reporte-dia {
  width: 100% !important;
  border-color: #48b9fd !important;
  border: 1px solid;
  border-radius: 8px;
}

.contenedor-badge {
  display: inline;
  border-color: #48b9fd !important;
  border: 1px solid;
  border-radius: 8px;
  width: 300px !important;
  height: 100%;
  text-align: center;
  padding: 7%;
}
/* ant-badge-status-dot ant-badge-status-default  */
.badge-violeta .ant-badge-status-default{
  background-color: #8F5DC1;
}
/* .ant-modal-detalle-relevo .ant-modal-content {
  border-radius: 16px !important;
} */

.ant-modal-detalle-relevo {
  width: 40% !important;
  top: 15% !important;
}

.ant-modal-detalle-relevo .ant-modal-content{
  border-radius: 15px !important;
}


.input-filtro-dias input {
  text-align: right !important;
}

.ant-input-number-input{
  padding-right: 30px !important;
}

.input-filtro-dias:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.row-naves {
  margin-bottom: 5%;
}

.circulo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-family: "MavenPro-SemiBold";
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #64b4e5;
}

.iconTransbordoCirculo {
  height: 40px;
  fill: #ffffff;
}

.circulo-transbordo{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-family: "MavenPro-SemiBold";
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #9e9fad;
}

.circulo-otros {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-family: "MavenPro-SemiBold";
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #e60aa3;
}

.tarjeta {
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.tarjeta-2 {
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #6FC9FF;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}


.tituloTarjeta {
  color: #061fcb;
  font-family: "MavenPro-Bold";
  font-size: 16px;
  padding-bottom: 3%;
}

.subtituloTarjeta {
  color: #262e2d;
  font-family: "MavenPro-SemiBold";
  font-size: 16px;
  padding-bottom: 3%;
}

.contenidoTarjeta {
  color: #262e2d;
  font-family: "MavenPro-Regular";
  font-size: 16px;
  padding-bottom: 3%;
}

.contenedorTrabajadores {
  height: 250px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.contenedorTrabajadores::-webkit-scrollbar {
  width: 5px;
}

.contenedorTrabajadores::-webkit-scrollbar-thumb {
  background: #48b9fd;
}

.btn-filtrar {
  width: 100% !important;
  text-align: 'right' !important; 
  margin-top: '10%' !important;
}

.cardTrabajadoresSinContrato{
  font-family: "MavenPro-Regular";
  font-size: 20px;
  color: #262E2D;
  text-align: center;
  padding-top: 10%;
  padding-left: 20%;
  padding-right: 20%;
}

@media (max-width: 1650px) {
  .contenidoCard {
    overflow: scroll;
  }

  .subtituloTarjeta {
    width: 750px;
  }

  .contenedorTrabajadores {
    width: 750px;
  }
}

@media (max-width: 1450px) {

  .circulo{
    height: 50px;
    width: 50px;
    font-size: 15px;
    line-height: 1px !important;
  }

  .circulo-transbordo{
    height: 50px;
    width: 50px;
    font-size: 15px;
    line-height: 1px !important;
  }

  .circulo-otros{
    height: 50px;
    width: 50px;
    font-size: 15px;
  }

}

@media (max-width: 700px) {

  .ant-modal-detalle-relevo {
    width: 100% !important;
    top: 15% !important;
  }
  
  .ant-modal-detalle-relevo .ant-modal-content{
    border-radius: 15px !important;
  }

  .contenidoCard {
    overflow: scroll;
  }

  .subtituloTarjeta {
    width: 700px;
  }

  .contenedorTrabajadores {
    width: 700px;
  }

}
