.descargar-excel-trabajador {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #37a537 !important;
  border-color: #319131;
  border-radius: 16px !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.descargar-excel-trabajadorX {
  width: 70px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #37a537 !important;
  border-color: #319131;
  border-radius: 16px !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.excel-carga-masiva {
  width: 189px !important;
  height: 35px !important;
  font-family: MavenPro-bold;
  color: #37a537 !important;
  background-color: #ffffff !important;
  border-color: #319131;
  border-radius: 16px !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.excel-reportes {
  width: 250px !important;
  height: 35px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #37a537 !important;
  border-color: #319131;
  border-radius: 16px !important;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.guardar-form-trabajador {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #e60aa3 !important;
  border-radius: 16px !important;
  border-color: #e60aa3;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.guardar-form-trabajador-disabled {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 16px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.acciones-trabajador-button {
  border-radius: 12px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: fit-content !important;
  padding: 0px 10px !important;
  height: 34px;
}

.acciones-trabajador-button :disabled {
  border: none;
  width: fit-content !important;
}

.acciones-trabajador-button.ant-tooltip-disabled-compatible-wrapper{
  background-color: #f5f5f5 !important; 
}

.hover-azul:hover {
  background-color: #0141d074;
  color: white;
}

.hover-celeste:hover {
  background-color: #64b4e5;
  color: white;
}

.hover-rojo:hover {
  background-color: #e61958;
  color: white;
}

@media screen and (max-width: 992px) {
  .descargar-excel-trabajador {
    width: fit-content !important;
    height: fit-content !important;
    color: #ffffff !important;
    background-color: #37a537 !important;
    border-radius: 11px !important;
    border-color: #319131;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  }
  .descargar-excel-trabajadorX {
    width: fit-content !important;
    height: fit-content !important;
    color: #ffffff !important;
    background-color: #37a537 !important;
    border-radius: 11px !important;
    border-color: #319131;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  }

  .guardar-form-trabajador {
    width: fit-content !important;
    height: fit-content !important;
    color: #ffffff !important;
    background-color: #e60aa3 !important;
    border-radius: 11px !important;
    border-color: #e60aa3;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  }

  .guardar-form-trabajador-disabled {
    width: fit-content !important;
    height: fit-content !important;
    color: #ffffff !important;
    background-color: #aaaaaa !important;
    border-radius: 11px !important;
    border-color: #aaaaaa;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  }
}

@media screen and (max-width: 577px) {
  .descargar-excel-trabajador {
    display: none !important;
  }
  .descargar-excel-trabajadorX {
    display: none !important;
  }
}
