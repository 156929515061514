.filtrar-contratos-btn {
  background-color: #48b9fd !important;
  width: 150px !important;
  border-radius: 6px !important;
  font-weight: 900 !important;
}

.ver-opciones-cont-btn {
  border: 1px solid #867d84 !important;
  background-color: #e60aa494 !important;
  width: 100% !important;
  border-radius: 6px !important;
  font-weight: 900 !important;
}

.ver-opciones-col {
  height: 100% !important;
}

.tarjeta-cont-trabajador {
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2) !important;

  padding: 20px 25px !important;
  margin-bottom: 20px;

  height: 225px !important;
}

.img-perfil-cont-wrapper {
  position: relative !important;
  width: 100% !important;
  padding-bottom: 100% !important;
}

.img-perfil-cont {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  border-radius: 50% !important;
}

.fechas-contrato-div {
  text-align: center !important;
  margin-top: 5px !important;
  width: 100% !important;
  background-color: #48b8fd5b !important;
  border: solid 1px #48b9fd !important;
  color: #3a92c9 !important;
}

.fechas-contrato-div p {
  width: fit-content !important;
  margin-bottom: 0px !important;
  margin-left: auto;
  margin-right: auto;
}

.trab-contrato-info-col p {
  margin-bottom: 0px !important;
}

.ver-opciones-btn-wrapper {
  margin: 15px 0px 0px auto !important;
  width: 100% !important;
}

.modal-header-wrapper {
  border: 1px solid rgb(235, 237, 240) !important;
  padding-bottom: 18px !important;

  margin-top: 12px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
  margin-bottom: 15px !important;

  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.contrato-div-container {
  width: 100% !important;
  border: 1px solid #dddddd !important;
  padding: 25px !important;
  margin-bottom: 10px !important;
}

.campos-formulario-contratos {
  border-radius: 8px !important;
  width: 100% !important;
}

.campos-formulario-generico {
  border-radius: 8px !important;
  width: 100% !important;
}

.formulario-contratos-submit-btn {
  border: 1px solid #e60aa3 !important;
  background-color: #e60aa494 !important;
  width: fit-content !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
}

.formulario-contratos-modificar-ficha {
  background-color: #48b9fd !important;
  width: fit-content !important;
  border-radius: 6px !important;
  font-weight: 900 !important;
}

.recontratar-warning-wrapper {
  position: absolute !important;
  z-index: 100;
  background-color: rgba(228, 228, 228, 0.9);
  border: 1px solid #ddd;
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

.opciones-contrato-btn {
  display: block !important;
  font-weight: 900 !important;
  background-color: #48b9fd !important;

  border-radius: 6px !important;
  width: 195px !important;
  margin-bottom: 10px;
}

.opciones-contrato-btn-eliminar {
  display: block !important;
  font-weight: 900 !important;
  border: 1px solid #867d84 !important;
  background-color: #e60aa494 !important;

  border-radius: 6px !important;
  width: 195px !important;
  margin-bottom: 10px;
}

.collapse-informacion .ant-collapse-item .ant-collapse-header{
    border-radius: 0px !important;
    border: 0px solid #e2e2e2 !important;
    min-height: 30px!important;
    max-height: 70px!important;
    height: auto !important;
    background-color: #f7f7f7 !important;
    font-family: MavenPro-bold !important;
    font-size: 14px !important;
    padding: 4px !important;
    margin: 0px 0px 0px 15px !important;
    box-shadow: 0px 0px 0px #00000029 !important;
}

.collapse-informacion .ant-collapse-item .ant-collapse-content{
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 0px #00000029;
}

@media screen and (max-width: 767px) {
  .filtrar-contratos-btn-col {
    text-align: right !important;
  }

  .opciones-contrato-btn {
    width: 100% !important;
  }
  .opciones-contrato-btn-eliminar {
    width: 100% !important;
  }
}
