.fondo-gris-input {
  background-color: #f3f3f3;
  border-radius: 22px;

}

.timeline-clock-icon{
    width: 20px;
}

.align-left-form div{
  text-align: left;
}

.checkout-masivo-btn{
  background-color: #E60AA3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
  width: 155px;
}