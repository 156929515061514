@font-face {
  font-family: "MavenPro-Black";
  src: url("../fonts/MavenPro-Black.ttf");
}

@font-face {
  font-family: "MavenPro-Bold";
  src: url("../fonts/MavenPro-Bold.ttf");
}

@font-face {
  font-family: "MavenPro-ExtraBold";
  src: url("../fonts/MavenPro-ExtraBold.ttf");
}

@font-face {
  font-family: "MavenPro-Medium";
  src: url("../fonts/MavenPro-Medium.ttf");
}

@font-face {
  font-family: "MavenPro-Regular";
  src: url("../fonts/MavenPro-Regular.ttf");
}

@font-face {
  font-family: "MavenPro-SemiBold";
  src: url("../fonts/MavenPro-SemiBold.ttf");
}

/*cursor-pointer*/
.cursor-pointer {
  cursor: pointer;
}
/*********************************************************/
/* CSS para las Card del Centro de Control */

.card-dashboard .ant-card-head {
  background-color: #f7f7f7 !important;
  height: 25px !important;
}

.card-dashboard .ant-card-head-title {
  color: #061fcb !important;
  text-align: start;
  margin-top: -5px;
  font-size: 17px;
}

.titulo-vencimiento {
  color: #061fcb !important;
  text-align: start;
  margin-top: -5px;
  font-size: 17px;
  font-weight: bold;
}
/*********************************************************/

/*css para quitar estilos al upload */
.ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 0px;
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 37%;
}
.ant-upload-select-picture-card img {
  height: 120px !important;
  width: 120px !important;
  border-radius: 50% !important;
}

.subir-foto {
  background-color: #48b9fd;
  /* bottom: 21%;
  right: -33%; */
  z-index: 2;
  position: absolute !important;
  right: -180% !important;
  bottom: 0px;
}

.upload-subir-foto {
  position: relative !important;
}

/*css para quitar estilos al upload */

.division {
  position: relative;
  width: 96%;
  color: #888888 !important;
}

/*css para content (div que compiensa a rodear todo el contenido)*/

.contenedor-general {
  padding: 16px 24px;
}

/*CSS PARA LAS TABLAS CELESTES (PRIMERA TABLA ENVIADA POR BARI) SI SE ENTREGA OTRO DISEÑO VALIDAR CON MIGUEL Y BARI*/

.cont-tabla-celeste table thead tr th {
  background-color: #64b4e5 !important;
  color: #ffffff;
  font-family: "MavenPro-Bold";
  font-size: 16px;
  border-right: 0px solid #f0f0f0 !important;
}

.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

.ant-table-row {
  font-family: "MavenPro-Regular" !important;
}

/*CSS PARA LAS TABLAS CELESTES (PRIMERA TABLA ENVIADA POR BARI) SI SE ENTREGA OTRO DISEÑO VALIDAR CON MIGUEL Y BARI*/

/*CSS PARA BOTONES (TODO EL SISTEMA)*/
.subir-archivos {
  border-radius: 12px !important;
  width: 15vw;
  height: 40px !important;
}

.div-input-search .ant-input {
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #48b9fd;
  color: #262e2d;
  font-family: "MavenPro-Regular";
}
.div-input-search .ant-input::placeholder {
  color: #262e2d;
  opacity: 0.5;
  font-family: "MavenPro-Regular";
}

.div-input-search .ant-input-group-addon .ant-btn {
  border-radius: 0px 8px 8px 0px;
  background-color: #48b9fd;
}

.btn-guardar-pequeño {
  min-width: 150px !important;
  height: 30px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #e60aa3 !important;
  border-radius: 16px !important;
  border-color: #e60aa3;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.btn-ver-datos-trabajador-asignar {
  min-width: 70px !important;
  height: 30px !important;
  background-color: #ffffff !important;
  border-radius: 16px !important;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.btn-guardar-pequeño-disabled {
  min-width: 150px !important;
  height: 30px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 16px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.btn-filtrar-celeste {
  min-width: 150px !important;
  height: 30px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #64b4e5 !important;
  border-radius: 16px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  font-size: 12px !important;
}

.guardar-form {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #e60aa3 !important;
  border-radius: 16px !important;
  border-color: #e60aa3;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.guardar-form-disabled {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 16px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.seleccionar-form {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #64b4e5 !important;
  border-radius: 16px !important;
  border-color: #64b4e5;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.seleccionar-form-disabled {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #aaaaaa !important;
  border-radius: 16px !important;
  border-color: #aaaaaa;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.descargar-excel {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #969696 !important;
  border-radius: 16px !important;
  border-color: #969696;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.descargar-excel-habilitado {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #37a537 !important;
  border-radius: 16px !important;
  border-color: #319131;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.descargar-excel-habilitado-delgado {
  width: 189px !important;
  height: 38px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #37a537 !important;
  border-radius: 16px !important;
  border-color: #319131;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.eliminar-habilitado {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #ca2828 !important;
  border-radius: 16px !important;
  border-color: #ca2828;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.btn-verde {
  width: 189px !important;
  height: 53px !important;
  font-family: MavenPro-bold;
  color: #ffffff !important;
  background-color: #29ba6c !important;
  border-color: #29ba6c;
  border-radius: 16px !important;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045);
}

.ant-table-cell {
  padding: 9px !important;
}

.imagen-tabla {
  width: 37px;
  height: 37px;
  border-radius: 20px;
}

.editar-button {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 3.8vw;
  height: 34px;
}

.imprimir-button {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 3.8vw;
  height: 34px;
}

.eliminar-button {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 3.8vw;
  height: 34px;
}

.transbordo-button {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 3.8vw;
  height: 34px;
}

.ver-button {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 3.8vw;
  height: 34px;
}

.ver-button:hover {
  background-color: #0141d074;
  color: white;
}

.editar-button:hover {
  background-color: #64b4e5;
  color: white;
}

.imprimir-button:hover {
  background-color: #3cadf0;
  color: white;
}

.eliminar-button:hover {
  background-color: #e61958;
  color: white;
}

.transbordo-button:hover {
  background-color: #52d68f;
}

.transbordo-button:hover .iconTransbordo {
  fill: #ffffff !important;
}

.boton-roles {
  border-radius: 10px;
  width: 132px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
}

.crear-embarco-button {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.crear-embarco-button-gris {
  background-color: #969696;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.button-delgado-rosado {
  background-color: #e60aa3;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.reporte-filtrar-btn {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

.reporte-filtrar-btn-without-size {
  background-color: #64b4e5;
  font-family: MavenPro-Bold;
  color: white;
  border-radius: 11px;
}

/*CSS PARA VIGENCIA (TABLAS)*/

/*modal*/

.title {
  font-family: MavenPro-bold;
  font-size: 20px;
  margin-bottom: 20px;
  background-color: transparent !important;
  padding: 5px 10px 5px 0px;
  border-bottom: 1px solid #e7e5e5;
}
.title h3 {
  color: #061fcb !important;
}

.switch {
  background-color: #e61958;
}

.ant-switch-checked {
  background-color: #1fc622;
}

.ant-picker {
  width: 100%;
  border-radius: 10px;
}

.ant-modal-body {
  padding: 15px;
}

/*css para card mantenedor foto perfil*/

.card-perfil {
  /* box-shadow: 0px 6px 12px #0000004b !important; */
  text-align: center !important;
  line-height: 15px !important;
  border: 1px solid #e7e5e5;
}

.card-formulario {
  /* box-shadow: 0px 6px 12px #0000004b !important; */
  border: 1px solid #e7e5e5;
}

.ant-input {
  border-radius: 7px;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 8px;
}

.ant-input-number {
  border-radius: 7px;
}
/* .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: white;
  cursor: not-allowed;
  opacity: 1;
  border: 0px;
} */

/*CANTIDAD DIAS DE TRABAJO CON */

.tablaCargos {
  width: 100%;
  margin-bottom: 60px;
  text-align: start;
}

.titulo-tabla {
  color: #262e2d;
  font-family: "MavenPro-Bold";
  text-align: left;
  margin-bottom: 20px;
}

.borrar-icon-table {
  background-color: #e60aa3;
  padding: 10px;
  height: 35px;
  width: 35px;
  border-radius: 18px;
  cursor: pointer;
}

.dashboard {
  background-color: #f4f4f4;
  box-shadow: 0px 3px 6px #00000029;
}

/* .status-faenas {
  width: 20%;
} */
.ant-badge-status-dot {
  width: 20px;
  height: 20px;
}

/* ESTILOS PARA CALENDARIO TRABAJADOR */

.card-col {
  background-color: #ffffff;

  padding: 0 !important;
}

.notify-badge {
  position: absolute;
  top: 115px;
  margin-left: 117px;
}

.sin-data {
  color: #969696;
  font-family: "MavenPro-Bold";
  font-size: 20px;
}

.site-page-header {
  padding: 10px;
}

.ant-popover-title {
  font-family: "MavenPro-SemiBold";
}

/* .rct-header-root {
  position: sticky !important;
  top: 160px!important;
  z-index: 100 !important;
} */

.rct-header-root {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
}

#cont-calendario-timeline .rct-header-root {
  position: sticky !important;
  top: 160px !important;
  z-index: 100 !important;
}

.menu-header-detalle-actividad {
  position: sticky !important;
  top: 0px !important;
  z-index: 100 !important;
  background-color: white !important;
}

.ant-table-column-sorter {
  color: #fff !important;
}

.ant-table-column-sorters::after {
  color: #fff !important;
}
