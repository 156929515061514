.plazas-table {
  max-width: 100% !important;
  margin-bottom: 20px !important;
}

.plazas-table,
.plazas-table th,
.plazas-table td {
  border: 1px solid #cccccc !important;
  border-collapse: collapse !important;

  text-align: center !important;
}

.plazas-table th,
.plazas-table td {
  padding: 5px !important;
}

.t-e-tags {
  height: 25px !important;
  margin-bottom: 5px !important;
  margin-right: 0px !important;
  width: 120px !important;

  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
