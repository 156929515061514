.epps-campos {
  min-width: 100% !important;
}

.epps-form-item {
  min-width: 100% !important;
}

.epp-add-btn {
  background-color: #e60aa3 !important;
  color: white !important;
}

.epps-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
  margin: 1em 0;
  padding: 0;
}

.epp-swal-div {
  text-align: justify !important;
}
