.maint-screen-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  background-color: #0143d0;
  opacity: 1;
  z-index: 9999;
  -webkit-transition: width 2s;
  cursor: progress;
}

.maint-card {
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);

  width: 70%;
  height: 70%;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px !important;
}

.maint-content-container {
  width: fit-content !important;
  height: fit-content !important;
  margin: auto;
  padding: 0px 60px;

  text-align: center !important;
  font-family: "MavenPro-Regular" !important;
}

.maint-pm-logo {
  width: 140px !important;
  height: auto !important;
  margin-bottom: 40px;
  margin-top: -40px;
}

@media screen and (max-width: 650px) {
  .maint-pm-logo {
    width: 90px !important;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .maint-card {
    width: 90%;
  }

  .maint-content-container {
    padding: 0px 10px;
  }
}
