.descargar-doc-button {
  border-radius: 8px;
  box-shadow: 0px 1px 3px #0141d074;
  border: none;
  width: 2.5vw;
  height: 28px;
}

.descargar-doc-button:hover {
  background-color: #64b4e5;
  color: white;
}
