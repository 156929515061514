.borde-inicio {
  border-left: 1px solid #001fcb !important;
  border-top: 1px solid #001fcb !important;
  border-bottom: 1px solid #001fcb !important;
}

.borde-fin {
  border-right: 1px solid #001fcb !important;
  border-top: 1px solid #001fcb !important;
  border-bottom: 1px solid #001fcb !important;
}

.borde-completo {
  border: 1px solid #001fcb !important;
}

.header-plaza-lighter {
  font-family: "MavenPro-Regular";
  font-size: 16px;
}
