.card_content--text {
    padding-top: 24px;
    padding-bottom: 15px;
    padding-left: 122px;
    padding-right: 122px;
    color: #061fcb;
    font-family: "MavenPro-Bold";
  }
  
  .formDescanso_card_title {
    font-size: 21px;
    font-family: "MavenPro-Bold";
    color: #061fcb;
    padding-top: 10px;
    margin-left: 18px;
  }
  
  .inputsFormDescanso_Text {
    font-family: "MavenPro-Regular";
  }
  
  .descansoBtn_guardar {
    margin-left: 50%;
  }
  
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .card_content--text {
      padding-left: 53px;
      padding-right: 53px;
    }
    .descansoBtn_guardar {
      margin-left: 61%;
    }
  }
  